.color__black {
  color: #1a282c; }

.color__white {
  color: #ffffff; }

.color__beige {
  color: #D9D9C9; }

.color__pink {
  color: #ffa3d1; }

.color__brown {
  color: #db7b1c; }

.color__light-blue {
  color: #98dde7; }

.color__blue {
  color: #1C21DF; }

.color__light-grey {
  color: #f6f6f6; }

.color__red {
  color: #FF542D; }

.color__green {
  color: #1E5558; }

.color__grey {
  color: #979797; }

.color__yellow {
  color: #ffcd00; }

.color__dark-grey {
  color: #737373; }

.color__soft-black {
  color: #364e55; }

.color__faint-grey {
  color: #f0f2f2; }

.color__wild-sand-grey {
  color: #e4e2df; }

.background-color__black {
  background-color: #1a282c; }

.background-color__white {
  background-color: #ffffff; }

.background-color__beige {
  background-color: #D9D9C9; }

.background-color__pink {
  background-color: #ffa3d1; }

.background-color__brown {
  background-color: #db7b1c; }

.background-color__light-blue {
  background-color: #98dde7; }

.background-color__blue {
  background-color: #1C21DF; }

.background-color__light-grey {
  background-color: #f6f6f6; }

.background-color__red {
  background-color: #FF542D; }

.background-color__green {
  background-color: #1E5558; }

.background-color__grey {
  background-color: #979797; }

.background-color__yellow {
  background-color: #ffcd00; }

.background-color__dark-grey {
  background-color: #737373; }

.background-color__soft-black {
  background-color: #364e55; }

.background-color__faint-grey {
  background-color: #f0f2f2; }

.background-color__wild-sand-grey {
  background-color: #e4e2df; }

.application-review-header {
  display: flex;
  justify-content: space-between; }

.application-review-header__heading {
  margin: 0;
  line-height: 1.3;
  color: #1E5558;
  font-family: 'Inter-Light';
  font-size: 36px;
  font-weight: 300;
  text-transform: capitalize;
  letter-spacing: normal; }

.application-review-header__small {
  display: block;
  margin: 9px 0 0;
  line-height: 1.5;
  color: #737373;
  font-family: 'Inter-Medium';
  font-size: 14px;
  font-weight: 400;
  letter-spacing: normal; }

.application-review-header__round-number {
  display: block;
  margin: 0px 0 0;
  line-height: 1.5;
  color: #737373;
  font-family: 'Inter-Medium';
  font-size: 14px;
  font-weight: 400;
  letter-spacing: normal; }

.application-review-header__print-trigger {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 125px;
  height: 56px;
  line-height: 1.5;
  color: #1a282c;
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal; }

.application-review-header__print-icon {
  margin: 0 8px 0 0; }

.application-review-top__back-trigger-icon {
  transform: scaleX(-1);
  display: inline-block;
  margin: 0 12px 0 0;
  width: 24px;
  height: auto; }
