#university-staff-applications-page {
  background-color: #f6f6f6;
  padding-top: 40px;
  margin: 0 auto; }
  @media (max-width: 768.98px) {
    #university-staff-applications-page {
      padding-top: 0px; } }
  #university-staff-applications-page #university-staff-applications-page__inner #university-staff-applications-page__inner__content #university-staff-applications-page__inner__content__inner #university-staff-applications-page__inner__content__top {
    padding-left: 40px;
    padding-right: 40px;
    display: flex;
    justify-content: space-between; }
    @media (max-width: 768.98px) {
      #university-staff-applications-page #university-staff-applications-page__inner #university-staff-applications-page__inner__content #university-staff-applications-page__inner__content__inner #university-staff-applications-page__inner__content__top {
        display: unset; } }
    #university-staff-applications-page #university-staff-applications-page__inner #university-staff-applications-page__inner__content #university-staff-applications-page__inner__content__inner #university-staff-applications-page__inner__content__top .university-staff-applications-page__inner__content__top__welcome-with-description {
      padding-right: 40px;
      padding-bottom: 40px; }
      @media (max-width: 768.98px) {
        #university-staff-applications-page #university-staff-applications-page__inner #university-staff-applications-page__inner__content #university-staff-applications-page__inner__content__inner #university-staff-applications-page__inner__content__top .university-staff-applications-page__inner__content__top__welcome-with-description {
          padding: 20px; } }
      #university-staff-applications-page #university-staff-applications-page__inner #university-staff-applications-page__inner__content #university-staff-applications-page__inner__content__inner #university-staff-applications-page__inner__content__top .university-staff-applications-page__inner__content__top__welcome-with-description .html-description {
        max-width: 800px; }
        #university-staff-applications-page #university-staff-applications-page__inner #university-staff-applications-page__inner__content #university-staff-applications-page__inner__content__inner #university-staff-applications-page__inner__content__top .university-staff-applications-page__inner__content__top__welcome-with-description .html-description b {
          font-weight: bold; }
        #university-staff-applications-page #university-staff-applications-page__inner #university-staff-applications-page__inner__content #university-staff-applications-page__inner__content__inner #university-staff-applications-page__inner__content__top .university-staff-applications-page__inner__content__top__welcome-with-description .html-description p {
          margin-bottom: 5px; }
        #university-staff-applications-page #university-staff-applications-page__inner #university-staff-applications-page__inner__content #university-staff-applications-page__inner__content__inner #university-staff-applications-page__inner__content__top .university-staff-applications-page__inner__content__top__welcome-with-description .html-description ul li {
          margin-left: 20px; }
          #university-staff-applications-page #university-staff-applications-page__inner #university-staff-applications-page__inner__content #university-staff-applications-page__inner__content__inner #university-staff-applications-page__inner__content__top .university-staff-applications-page__inner__content__top__welcome-with-description .html-description ul li::before {
            content: "\2022";
            /* Add content: \2022 is the CSS Code/unicode for a bullet */
            color: #1a282c;
            /* Change the color */
            font-weight: bold;
            /* If you want it to be bold */
            display: inline-block;
            /* Needed to add space between the bullet and the text */
            width: 0.8em;
            /* Also needed for space (tweak if needed) */
            margin-left: -0.8em;
            /* Also needed for space (tweak if needed) */
            font-size: 24px;
            line-height: normal; }
      #university-staff-applications-page #university-staff-applications-page__inner #university-staff-applications-page__inner__content #university-staff-applications-page__inner__content__inner #university-staff-applications-page__inner__content__top .university-staff-applications-page__inner__content__top__welcome-with-description .university-staff-name {
        margin-bottom: 15px; }
        #university-staff-applications-page #university-staff-applications-page__inner #university-staff-applications-page__inner__content #university-staff-applications-page__inner__content__inner #university-staff-applications-page__inner__content__top .university-staff-applications-page__inner__content__top__welcome-with-description .university-staff-name .welcome-user {
          margin-left: 10px; }
    #university-staff-applications-page #university-staff-applications-page__inner #university-staff-applications-page__inner__content #university-staff-applications-page__inner__content__inner #university-staff-applications-page__inner__content__top .university-staff-applications-page__inner__content__top__filter-with-search {
      flex-shrink: 0; }
      @media (max-width: 768.98px) {
        #university-staff-applications-page #university-staff-applications-page__inner #university-staff-applications-page__inner__content #university-staff-applications-page__inner__content__inner #university-staff-applications-page__inner__content__top .university-staff-applications-page__inner__content__top__filter-with-search {
          padding: 20px; } }
      #university-staff-applications-page #university-staff-applications-page__inner #university-staff-applications-page__inner__content #university-staff-applications-page__inner__content__inner #university-staff-applications-page__inner__content__top .university-staff-applications-page__inner__content__top__filter-with-search .university-staff-filter-combination {
        display: flex;
        align-items: center;
        margin-top: 15px; }
        #university-staff-applications-page #university-staff-applications-page__inner #university-staff-applications-page__inner__content #university-staff-applications-page__inner__content__inner #university-staff-applications-page__inner__content__top .university-staff-applications-page__inner__content__top__filter-with-search .university-staff-filter-combination .filter-left-section .filter-selector .filter-selector__heading {
          margin-bottom: 0px; }
        #university-staff-applications-page #university-staff-applications-page__inner #university-staff-applications-page__inner__content #university-staff-applications-page__inner__content__inner #university-staff-applications-page__inner__content__top .university-staff-applications-page__inner__content__top__filter-with-search .university-staff-filter-combination .filter-left-section .filter-selector button:hover {
          transform: scale(1.04);
          transition: transform 0.25s ease 0s; }
        #university-staff-applications-page #university-staff-applications-page__inner #university-staff-applications-page__inner__content #university-staff-applications-page__inner__content__inner #university-staff-applications-page__inner__content__top .university-staff-applications-page__inner__content__top__filter-with-search .university-staff-filter-combination .filter-right-section {
          display: flex;
          align-items: center; }
          #university-staff-applications-page #university-staff-applications-page__inner #university-staff-applications-page__inner__content #university-staff-applications-page__inner__content__inner #university-staff-applications-page__inner__content__top .university-staff-applications-page__inner__content__top__filter-with-search .university-staff-filter-combination .filter-right-section #update-all-cta {
            background: #ffffff;
            color: #696969;
            padding: 16px 22px 16px 24px;
            margin-left: 12px;
            margin-right: 12px;
            line-height: 1; }
            #university-staff-applications-page #university-staff-applications-page__inner #university-staff-applications-page__inner__content #university-staff-applications-page__inner__content__inner #university-staff-applications-page__inner__content__top .university-staff-applications-page__inner__content__top__filter-with-search .university-staff-filter-combination .filter-right-section #update-all-cta:disabled {
              color: #ffffff;
              border: 0px !important; }
          #university-staff-applications-page #university-staff-applications-page__inner #university-staff-applications-page__inner__content #university-staff-applications-page__inner__content__inner #university-staff-applications-page__inner__content__top .university-staff-applications-page__inner__content__top__filter-with-search .university-staff-filter-combination .filter-right-section #reset {
            background: #ffffff;
            color: #696969;
            padding: 16px 22px 16px 24px; }
      #university-staff-applications-page #university-staff-applications-page__inner #university-staff-applications-page__inner__content #university-staff-applications-page__inner__content__inner #university-staff-applications-page__inner__content__top .university-staff-applications-page__inner__content__top__filter-with-search .university-staff-home-page__bottom__search-box .university-staff-search-action .field {
        margin-bottom: 0px;
        display: flex;
        justify-content: flex-end; }
        #university-staff-applications-page #university-staff-applications-page__inner #university-staff-applications-page__inner__content #university-staff-applications-page__inner__content__inner #university-staff-applications-page__inner__content__top .university-staff-applications-page__inner__content__top__filter-with-search .university-staff-home-page__bottom__search-box .university-staff-search-action .field input {
          background: #ffffff;
          max-width: 297px; }
        #university-staff-applications-page #university-staff-applications-page__inner #university-staff-applications-page__inner__content #university-staff-applications-page__inner__content__inner #university-staff-applications-page__inner__content__top .university-staff-applications-page__inner__content__top__filter-with-search .university-staff-home-page__bottom__search-box .university-staff-search-action .field .field__search-icon {
          top: 35px !important; }

#staff__update_all .modal__inner #staff-update-all-modal-content {
  width: 100%; }
  #staff__update_all .modal__inner #staff-update-all-modal-content .staff-update-all-head_container {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end; }
    #staff__update_all .modal__inner #staff-update-all-modal-content .staff-update-all-head_container .event-head {
      cursor: pointer; }
  #staff__update_all .modal__inner #staff-update-all-modal-content .user-staff-update-all-add__form .scorecard-fields {
    margin-top: 25px; }
  #staff__update_all .modal__inner #staff-update-all-modal-content .user-staff-update-all-add__form .university-staff-application-review-field-footer__top__save-submit-button {
    justify-content: center; }
