.user-account-page {
  max-width: 960px;
  margin: 0 auto; }
  @media (max-width: 768.98px) {
    .user-account-page .form-section .icon-header {
      margin-left: 55px; } }
  .user-account-page .event-head {
    margin-bottom: 3.5rem; }
    @media (max-width: 768.98px) {
      .user-account-page .event-head {
        margin-left: 20px; } }
  .user-account-page #applicant-screen__events-table td {
    word-break: break-all; }
  .user-account-page .user-profile-page-user-details-section {
    margin-bottom: 30px;
    padding-top: 115px;
    align-items: center; }
    .user-account-page .user-profile-page-user-details-section .react-tel-input {
      margin-top: 8px; }
    .user-account-page .user-profile-page-user-details-section .username-and-year .H1DesktopBlack {
      color: #1a282c;
      font-weight: 600;
      font-size: 26px !important;
      margin-bottom: 5px; }
    .user-account-page .user-profile-page-user-details-section .BodyDefaultRegularBlack {
      font-style: italic; }
    .user-account-page .user-profile-page-user-details-section .user-account-default-profile-image {
      width: 80px;
      height: 80px;
      background: #f6f6f6;
      border-radius: 100%;
      margin-right: 22px;
      display: flex;
      align-items: center;
      justify-content: center; }
      .user-account-page .user-profile-page-user-details-section .user-account-default-profile-image #user-account-page-user-icon {
        width: 41px; }
  .user-account-page .section .Reset-Password {
    font-family: 'Inter-Medium';
    font-size: 16px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #1C21DF;
    line-height: 1.5;
    position: relative;
    bottom: 35px; }

.H5HeadingDivider {
  margin: 16px 0 41px 0px;
  border: dashed 1px #e6e9d7; }

#account-page {
  margin: 90px auto;
  max-width: 895px; }

#account-page__inner {
  margin: 0 20px; }

#account-page__preferences {
  display: flex;
  flex-direction: column;
  margin-top: -40px; }

.account-page__preference {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #f6f6f6;
  padding: 15px 0 20px;
  flex-direction: row !important; }
  .account-page__preference .notification-prefrence_description {
    color: #737373;
    font-style: italic;
    max-width: 80%; }
  .account-page__preference .notification-prefrence-text {
    width: 75%; }
  .account-page__preference .notification-prefrence-slider {
    width: 25%;
    text-align: right; }

.applicant-profile__form .profile-all-section-container {
  border: 2px solid #ebebeb;
  padding: 30px 30px 0 30px;
  border-radius: 10px 10px 0px 0px; }
  .applicant-profile__form .profile-all-section-container .profile-image-upload-with-help-text #profile_image_help_text {
    margin-bottom: unset; }
    .applicant-profile__form .profile-all-section-container .profile-image-upload-with-help-text #profile_image_help_text .field__text-field {
      visibility: hidden; }
  .applicant-profile__form .profile-all-section-container .profile-image-user-message {
    margin-top: 15px; }
  .applicant-profile__form .profile-all-section-container .section.group .field .error-email {
    margin-top: 0px; }
  @media (max-width: 768.98px) {
    .applicant-profile__form .profile-all-section-container {
      padding: 30px 15px 0 15px; } }

.applicant-profile__form .profile-all-section-footer-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 15px;
  padding-bottom: 15px;
  border-right: 2px solid #ebebeb;
  border-bottom: 2px solid #ebebeb;
  border-left: 2px solid #ebebeb;
  margin-bottom: 50px;
  border-radius: 0 0 10px 10px; }

.applicant-profile__form #still-employed-by-taco-bell {
  margin-bottom: 10px; }

.applicant-profile__form .react-tel-input {
  margin-top: 8px; }
  .applicant-profile__form .react-tel-input .field__tel-field {
    margin-left: 0;
    padding: 0 50px; }
  .applicant-profile__form .react-tel-input .flag-dropdown {
    border: 0px;
    background: #ffffff; }
    .applicant-profile__form .react-tel-input .flag-dropdown .selected-flag {
      width: 45px;
      padding: 0px 10px 0px 10px; }

.image-upload-field__container {
  height: 87px;
  width: 87px;
  border-radius: 4px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 4px solid #ffffff;
  background: #f6f6f6;
  color: #ffffff; }
  @media (max-width: 768.98px) {
    .image-upload-field__container {
      margin-bottom: 40px; } }
  .image-upload-field__container input[type="file"] {
    height: 95px;
    width: 95px;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 99;
    cursor: pointer; }
  .image-upload-field__container__icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: -8px;
    right: -8px;
    background: #1E5558;
    padding: 5px;
    border-radius: 100%; }
  .image-upload-field__container__avatar {
    width: 87px;
    height: 87px;
    object-fit: cover;
    border-radius: 4px; }

.applicant-profile__form .profile-all-section-container .group:before {
  content: unset;
  display: unset; }

.applicant-profile__form .profile-all-section-container .section.group {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px; }

@media (max-width: 768.98px) {
  .applicant-profile__form .profile-all-section-container .group:before {
    content: unset;
    display: unset; }
  .applicant-profile__form .profile-all-section-container .section.group {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 10px; } }

.profile-field-column .profile-image-upload-with-help-text {
  display: flex;
  justify-content: space-between; }

#profile-page__cta {
  margin: auto;
  width: 150px;
  align-items: center;
  justify-content: center;
  height: 50px; }
