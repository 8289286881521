.H1DesktopGreen {
  font-family: 'Inter-Light';
  font-size: 36px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  color: #1E5558; }

.H1DesktopBlack {
  font-family: 'Inter-Light';
  font-size: 36px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  color: #1a282c; }

.H1DesktopWhite {
  font-family: 'Inter-Light';
  font-size: 36px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  color: #f6f6f6; }

.H2Desktop {
  font-family: 'Inter-Medium';
  font-size: 32px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal; }

.H2DesktopGreen {
  font-family: 'Inter-Medium';
  font-size: 32px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.11;
  letter-spacing: normal;
  color: #1E5558; }

.DesktopGreen {
  font-family: 'Inter-Medium';
  font-size: 18px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: normal;
  color: #1E5558; }

.H2DesktopWhite {
  font-family: 'Inter-Medium';
  font-size: 32px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  color: #ffffff; }
  @media (max-width: 768.98px) {
    .H2DesktopWhite {
      font-size: 30px;
      line-height: 1.33; } }

.H3Desktop {
  font-family: 'Inter-Medium';
  font-size: 29px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal; }
  @media (max-width: 768.98px) {
    .H3Desktop {
      font-size: 24px;
      line-height: 2; } }

.H3BlackDesktop {
  font-family: 'Inter-Medium';
  font-size: 29px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1a282c; }

.H3WhiteDesktop {
  font-family: 'Inter-Medium';
  font-size: 29px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #ffffff; }

.H4DesktopGreen {
  font-family: 'Inter-Medium';
  font-size: 26px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1E5558; }

.H5DesktopGreen {
  font-family: 'Inter-Medium';
  font-size: 23px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1E5558; }

.H5DesktopGrey {
  font-family: 'Inter-Medium';
  font-size: 23px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #979797; }

.H5DesktopBlack {
  font-family: 'Inter-Medium';
  font-size: 23px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1a282c; }

.H6DesktopBlack, #recommender__create textarea {
  font-family: 'Inter-Medium';
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1a282c; }

.H4DesktopWhite {
  font-family: 'Inter-Medium';
  font-size: 26px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff; }

.H4DesktopBlack {
  font-family: 'Inter-Medium';
  font-size: 26px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1a282c; }

.H6Desktop {
  font-family: 'Inter-Medium';
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal; }

.H6DesktopGrey {
  font-family: 'Inter-Medium';
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #979797; }

.H6DesktopBlack, #recommender__create textarea {
  font-family: 'Inter-Medium';
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1a282c; }

.H6DesktopGreen {
  font-family: 'Inter-Medium';
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1E5558; }

.PDesktopGreen {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: black; }

.H6DesktopWhite {
  font-family: 'Inter-Medium';
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #ffffff; }

.BodyLargeWhite {
  font-family: 'Inter-Medium';
  font-size: 18px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: normal;
  color: #ffffff; }

.BodyDefault {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal; }

.BodyDefaultBold {
  font-family: 'Inter-Bold';
  font-size: 16px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal; }

.BodyDefaultBoldGrey {
  font-family: 'Inter-Bold';
  font-size: 16px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #979797; }

.BodyDefaultBoldWhite {
  font-family: 'Inter-Bold';
  font-size: 16px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #ffffff; }

.BodyDefaultBoldBlack {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1a282c; }

.BodyDefaultRegularGrey {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #979797; }

.BodyDefaultRegularBlack {
  font-family: 'Inter-Regular';
  font-size: 16px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1a282c; }

.BodySmallMediumGrey {
  font-family: 'Inter-Medium';
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #979797; }

.BodySmallMediumWhite {
  font-family: 'Inter-Medium';
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #ffffff; }

.BodySmallMediumBlack {
  font-family: 'Inter-Medium';
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1a282c; }

.BodySmallMedium {
  font-family: 'Inter-Medium';
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal; }

.CTA {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal; }

.CTABlue {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1C21DF; }

.CTAGrey {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1a282c; }

.CTAGreen {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1E5558; }

.CTABlack {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1a282c; }

.CTARed {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #FF542D; }

.CTAWhite {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #ffffff; }

.CTAYellow {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #ffcd00; }

.BodySmallRegular {
  font-family: 'Inter-Medium';
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal; }

.BodySmallRegularWhite {
  font-family: 'Inter-Medium';
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #ffffff; }

.BodySmallRegularGrey {
  font-family: 'Inter-Medium';
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #737373; }

.BodySmallRegularGreen {
  font-family: 'Inter-Medium';
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1E5558; }

.BodySmallRegularBlack {
  font-family: 'Inter-Medium';
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1a282c; }

.LabelGrey {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #737373; }

.LabelWhite {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #ffffff; }

.EyebrowBlack {
  font-family: 'Inter-Medium';
  font-size: 10px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1a282c;
  text-transform: uppercase; }

.EyebrowWhite {
  font-family: 'Inter-Medium';
  font-size: 10px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #ffffff;
  text-transform: uppercase; }

.EyebrowGreen {
  font-family: 'Inter-Medium';
  font-size: 10px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1E5558;
  text-transform: uppercase; }

.EyebrowGrey {
  font-family: 'Inter-Medium';
  font-size: 10px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #737373;
  text-transform: uppercase; }

.CTA-Anchor-Tag-css {
  display: flex;
  justify-content: center;
  width: 150px;
  height: 50px;
  color: #ffffff;
  background-color: #1C21DF;
  border: 1px solid #1C21DF;
  letter-spacing: normal;
  font-family: 'Inter-Medium', sans-serif;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  transition: transform 0.25s;
  border-radius: 5px;
  cursor: pointer; }
  .CTA-Anchor-Tag-css__Text {
    margin: auto;
    text-align: center;
    display: flex;
    align-items: center; }
    .CTA-Anchor-Tag-css__Text svg {
      margin-right: 12px;
      transform: rotate(180deg); }

#recommender__create {
  align-items: flex-start;
  overflow-y: scroll; }
  @media (max-width: 768.98px) {
    #recommender__create {
      max-width: calc(100% - 80px);
      max-height: 60vh; } }
  @media (max-width: 479.98px) {
    #recommender__create.modal {
      z-index: 10000 !important; } }
  #recommender__create textarea {
    border: 1px;
    border-radius: 4px;
    background-color: #ffffff;
    border: 1px solid #1E5558; }
    #recommender__create textarea:disabled {
      color: #979797; }
  #recommender__create #task-modal-content .task-head_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-self: center;
    margin-bottom: 30px; }
    #recommender__create #task-modal-content .task-head_container .event-head {
      cursor: pointer; }
  #recommender__create #task-modal-content .applicant-task__submit {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 25px; }
    #recommender__create #task-modal-content .applicant-task__submit #task-page__cta {
      padding: 8px 20px; }
    #recommender__create #task-modal-content .applicant-task__submit #task-page__cta__cancel {
      border: none;
      padding: 8px 20px; }
  #recommender__create .application-section__question {
    margin: 25px 0 0; }
    @media (min-width: 769px) {
      #recommender__create .application-section__question {
        margin: 25px 0 0; } }
    #recommender__create .application-section__question .css-1jk9nnp-Control {
      margin-top: 0; }
  #recommender__create .application-section__label {
    color: #1a282c;
    font-family: 'Inter-Medium';
    font-size: 16px;
    font-weight: 400;
    opacity: 0.6;
    line-height: 1.5;
    letter-spacing: normal; }
    @media (min-width: 769px) {
      #recommender__create .application-section__label {
        opacity: 1; } }
  #recommender__create .application-section__textarea {
    margin: 0; }

.modal {
  z-index: 100 !important; }
