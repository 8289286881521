#donor-admin-page {
  background-color: #F7F7F3;
  padding-top: 64px;
  margin: 0 auto;
  min-height: calc(100vh - 144px); }
  @media (max-width: 768.98px) {
    #donor-admin-page {
      padding: 15px; } }
  #donor-admin-page #donor-admin-page__top {
    display: flex;
    margin: 0 40px 40px 40px;
    justify-content: space-between;
    align-items: center; }
    @media (max-width: 768.98px) {
      #donor-admin-page #donor-admin-page__top {
        display: unset; }
        #donor-admin-page #donor-admin-page__top #donor-scholarships-page__top-actions {
          flex-direction: row; }
          #donor-admin-page #donor-admin-page__top #donor-scholarships-page__top-actions .record-per-page-field-container {
            margin-top: 15px; }
          #donor-admin-page #donor-admin-page__top #donor-scholarships-page__top-actions .field {
            margin-left: 0px; }
            #donor-admin-page #donor-admin-page__top #donor-scholarships-page__top-actions .field .field__text-field.search {
              width: 100%; } }
    #donor-admin-page #donor-admin-page__top #donor-scholarships-page__top-copy #donor-scholarships-page__greeting {
      margin: 0 0 0px 0px; }
  #donor-admin-page .donor-admin-page__container {
    display: flex; }
    @media (max-width: 768.98px) {
      #donor-admin-page .donor-admin-page__container {
        display: unset; } }
    #donor-admin-page .donor-admin-page__container .donor-admin-page__tabs {
      width: 320px;
      background-color: #ffffff;
      margin-right: 20px;
      box-sizing: border-box; }
      @media (max-width: 768.98px) {
        #donor-admin-page .donor-admin-page__container .donor-admin-page__tabs {
          width: 100%;
          margin-bottom: 10px; } }
      #donor-admin-page .donor-admin-page__container .donor-admin-page__tabs ul .donor-admin-page__menu-list-item {
        padding: 15px; }
        #donor-admin-page .donor-admin-page__container .donor-admin-page__tabs ul .donor-admin-page__menu-list-item:hover {
          background: #35888D; }
          #donor-admin-page .donor-admin-page__container .donor-admin-page__tabs ul .donor-admin-page__menu-list-item:hover svg {
            filter: brightness(0) invert(1); }
          #donor-admin-page .donor-admin-page__container .donor-admin-page__tabs ul .donor-admin-page__menu-list-item:hover .side-drawer-menu-list__item-copy .CTAGrey {
            color: #ffffff; }
        #donor-admin-page .donor-admin-page__container .donor-admin-page__tabs ul .donor-admin-page__menu-list-item.CTAGreen {
          background: #1E5558; }
          #donor-admin-page .donor-admin-page__container .donor-admin-page__tabs ul .donor-admin-page__menu-list-item.CTAGreen .side-drawer-menu-list__item-copy .CTAGreen {
            color: #ffffff;
            font-style: italic; }
      #donor-admin-page .donor-admin-page__container .donor-admin-page__tabs ul li {
        cursor: pointer;
        display: flex;
        align-items: center; }
        #donor-admin-page .donor-admin-page__container .donor-admin-page__tabs ul li svg {
          min-width: 32px; }
        #donor-admin-page .donor-admin-page__container .donor-admin-page__tabs ul li .side-drawer-menu-list__item-copy {
          margin-left: 10px; }
    #donor-admin-page .donor-admin-page__container .donor-admin-page__content {
      width: calc(100% - 340px); }
      #donor-admin-page .donor-admin-page__container .donor-admin-page__content .table__container table {
        margin-bottom: 100px; }
      @media (max-width: 768.98px) {
        #donor-admin-page .donor-admin-page__container .donor-admin-page__content {
          width: 100%; } }
