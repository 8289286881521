.user-account-donor-page {
  max-width: 100%;
  margin: 0 auto;
  padding: 0px 20px; }
  .user-account-donor-page .event-head {
    margin-bottom: 0rem; }
    @media (max-width: 768.98px) {
      .user-account-donor-page .event-head {
        margin-bottom: 20px; } }
  .user-account-donor-page .donor-page-title-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 115px;
    margin-bottom: 3.5rem; }
    @media (max-width: 768.98px) {
      .user-account-donor-page .donor-page-title-section {
        flex-direction: column-reverse;
        text-align: center; } }
    .user-account-donor-page .donor-page-title-section__description .H1DesktopGreen {
      color: #1a282c;
      font-weight: 600;
      font-size: 26px !important; }
    @media (max-width: 768.98px) {
      .user-account-donor-page .donor-page-title-section__description .H2DesktopGreen {
        margin-bottom: 20px; } }
    @media (max-width: 768.98px) {
      .user-account-donor-page .donor-page-title-section__icon svg {
        height: 80px; } }
  .user-account-donor-page .section .Reset-Password {
    font-family: 'Inter-Medium';
    font-size: 16px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #1C21DF;
    line-height: 1.5; }
  .user-account-donor-page #applicant-screen__disbursements-table {
    min-height: 70vh; }
  @media (max-width: 768.98px) {
    .user-account-donor-page #applicant-screen__donor-table table tbody td {
      max-width: unset;
      word-break: unset;
      vertical-align: top; } }

.H5HeadingDivider {
  margin: 16px 0 41px 0px;
  border: dashed 1px #e6e9d7; }
