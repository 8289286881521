.donation-container {
  max-width: 1110px;
  margin: 64px auto;
  padding-bottom: 16px; }
  .donation-container h1 {
    font-size: 26px;
    margin-top: 20px;
    font-family: "Inter-Light"; }
  .donation-container h6 {
    font-family: "Inter-Light";
    padding: 12px 0px;
    line-height: 1.3;
    width: 80%;
    margin: auto; }
  .donation-container .header-section {
    display: flex;
    flex-direction: column;
    text-align: center;
    background-color: #1e5558;
    color: white;
    margin-bottom: 12px;
    border-radius: 8px;
    margin-left: 16px;
    margin-right: 16px; }
  .donation-container .donation-container_inner {
    margin: 0 40px; }
    .donation-container .donation-container_inner .main-section {
      gap: 80px;
      padding: 30px 0;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      margin-top: 20px;
      border-top: 1px solid #737373; }
      @media (max-width: 768.98px) {
        .donation-container .donation-container_inner .main-section {
          grid-template-columns: repeat(1, 1fr); } }
      .donation-container .donation-container_inner .main-section .section-text {
        margin-top: 22px;
        font-family: "Inter-Light"; }
        .donation-container .donation-container_inner .main-section .section-text p {
          margin-top: 8px; }
      .donation-container .donation-container_inner .main-section .image-section img {
        max-height: 300px;
        min-height: 300px;
        width: 100%;
        border: 1px solid #1a282c; }
  .donation-container .started-section {
    display: flex;
    flex-direction: column;
    text-align: center;
    background-color: #592840;
    color: #ffffff;
    width: 80%;
    margin: auto;
    border-radius: 8px;
    font-family: "Inter-Light";
    margin-bottom: 22px;
    padding-bottom: 10px; }
    .donation-container .started-section h1 {
      font-size: 26px;
      margin-top: 20px; }
    .donation-container .started-section h6 {
      padding: 8px; }
      .donation-container .started-section h6 a {
        text-decoration: underline;
        font-size: large; }
