.application-section {
  padding: 16px 0 0; }
  @media (min-width: 769px) {
    .application-section {
      margin: 80px 0 0;
      padding: unset; } }
  .application-section .pull-right {
    float: right; }

.application-section__title {
  position: relative;
  padding: 0 0 20px;
  width: 100%;
  line-height: 30px;
  font-family: 'Inter-Regular', sans-serif;
  font-size: 23px;
  font-weight: 400; }
  .application-section__title div a {
    color: #1C21DF;
    text-decoration: underline; }
  @media (min-width: 769px) {
    .application-section__title {
      padding: 0 0 24px;
      line-height: 30px;
      font-size: 23px; } }

@media (max-width: 479.98px) {
  .application-section__title-section-mobile {
    padding: 0px 24px;
    width: 90%;
    font-size: 20px; } }

.application-section--last .application-section__title {
  border-bottom: none; }
  @media (min-width: 769px) {
    .application-section--last .application-section__title {
      padding: 0 0 24px; } }

.application-section__count {
  display: block;
  margin: 8px 0 0;
  line-height: 1;
  color: #979797;
  font-size: 14px;
  letter-spacing: 0.31px; }
  @media (min-width: 769px) {
    .application-section__count {
      color: #737373;
      letter-spacing: unset; } }

.application-section__subtitle {
  display: block;
  margin: 8px 0 0;
  padding-right: 1rem;
  line-height: 1;
  color: #1a282c !important;
  font-size: 14px;
  letter-spacing: normal !important;
  font-family: 'Inter-Regular';
  line-height: 1.5; }
  .application-section__subtitle a {
    cursor: pointer;
    text-decoration: underline;
    color: #1E5558; }
  .application-section__subtitle ul {
    list-style-type: disc;
    margin: 1em;
    margin-left: 40px; }
  .application-section__subtitle b {
    font-weight: bold; }
  .application-section__subtitle i {
    font-style: italic; }
  .application-section__subtitle u {
    text-decoration: underline; }
  .application-section__subtitle ol {
    list-style-type: auto;
    margin: 1em;
    margin-left: 40px; }
  .application-section__subtitle table thead th {
    font-weight: bold; }
  .application-section__subtitle img {
    max-width: 100%; }
  @media (min-width: 769px) {
    .application-section__subtitle {
      color: #737373;
      letter-spacing: unset; } }

.application-section__triangle {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%) translateY(-10px);
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 7px 0 7px 6px;
  border-color: transparent transparent transparent #1C21DF; }
  @media (min-width: 769px) {
    .application-section__triangle {
      border-width: 0 7px 6px 7px;
      border-color: transparent transparent #1C21DF transparent; } }

@media (min-width: 769px) {
  .application-section.is-active .application-section__triangle {
    border-width: 6px 7px 0 7px;
    border-color: #1C21DF transparent transparent transparent; } }

.application-section.is-completed .application-section__triangle {
  display: none; }

.application-section__completed-badge {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%) translateY(-10px);
  display: none;
  justify-content: center;
  align-items: center;
  width: 41px;
  height: 32px;
  background-color: #1E5558;
  border-radius: 16px; }

.application-section.is-completed .application-section__completed-badge {
  display: flex; }

.application-section__content {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 56px 20px 28px;
  background-color: #ffffff;
  z-index: 10; }
  @media (max-width: 479.98px) {
    .application-section__content {
      background-color: unset; } }
  @media (max-width: 768.98px) {
    .application-section__content {
      padding: 0px 20px 20px; } }
  @media (min-width: 769px) {
    .application-section__content {
      position: unset;
      top: unset;
      right: unset;
      bottom: unset;
      left: unset;
      display: block;
      padding: unset;
      min-height: auto;
      background-color: unset;
      overflow: unset;
      z-index: unset; } }
  .application-section__content .add-recommender-with-question-btn-container {
    display: flex;
    justify-content: center; }
    .application-section__content .add-recommender-with-question-btn-container .CTAGrey {
      cursor: pointer; }
    .application-section__content .add-recommender-with-question-btn-container #primary-btn {
      color: #1C21DF;
      background-color: #ffffff; }
      .application-section__content .add-recommender-with-question-btn-container #primary-btn.flat {
        padding: 8px 30px; }
      .application-section__content .add-recommender-with-question-btn-container #primary-btn.outline {
        color: #1C21DF; }
      .application-section__content .add-recommender-with-question-btn-container #primary-btn.border {
        border: 2px solid #1C21DF;
        margin-top: 20px; }
      .application-section__content .add-recommender-with-question-btn-container #primary-btn .cta-button__text {
        display: flex;
        align-items: center; }
    .application-section__content .add-recommender-with-question-btn-container .application__submit-trigger {
      background-color: #ffffff !important;
      color: #1C21DF;
      border: 1px solid #1C21DF;
      padding: 12px 25px 12px 25px;
      height: 34px; }
    .application-section__content .add-recommender-with-question-btn-container .icon-with-role-label {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column; }
    .application-section__content .add-recommender-with-question-btn-container__icon {
      cursor: pointer; }
    .application-section__content .add-recommender-with-question-btn-container svg {
      cursor: pointer; }
  .application-section__content .application-review-attachment {
    padding: 35px 0; }
    .application-section__content .application-review-attachment .application-review-attachment__main {
      margin: 0 0 0 0px;
      flex-direction: column;
      align-items: flex-start; }
      .application-section__content .application-review-attachment .application-review-attachment__main svg {
        margin-right: 10px; }
    .application-section__content .application-review-attachment .application-review-attachment__trigger-view-file {
      margin: 0 0 0 auto; }
      .application-section__content .application-review-attachment .application-review-attachment__trigger-view-file .edit-delete-icon-container__edit-btn {
        margin-right: 10px;
        cursor: pointer; }
      .application-section__content .application-review-attachment .application-review-attachment__trigger-view-file .edit-delete-icon-container__delete-btn {
        margin-top: 10px;
        cursor: pointer; }

@media (min-width: 769px) {
  .application-section.is-active .application-section__content {
    display: none !important; } }

.application-section.is-active .application-section__content {
  display: flex;
  flex-direction: column; }

.application-section__mobile-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  height: 56px;
  background-color: #ffffff;
  border-bottom: 1px solid #f6f6f6;
  z-index: 15; }
  @media (min-width: 769px) {
    .application-section__mobile-top {
      display: none; } }

.application-section__mobile-back {
  display: flex;
  align-items: center;
  padding: 0 20px;
  height: 100%;
  color: #1C21DF;
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  line-height: 1.5; }

.application-section__mobile-back-icon {
  transform: scaleX(-1);
  margin: 0 12px 0 0; }

.application-section__mobile-back-icon path {
  fill: #1C21DF; }

.application-section__mobile-heading {
  margin: 42px 0 0;
  line-height: 32px;
  color: #008291;
  font-family: 'Inter-Medium';
  font-size: 24px;
  letter-spacing: normal;
  line-height: 1.5;
  font-weight: 400; }
  @media (min-width: 769px) {
    .application-section__mobile-heading {
      display: none; } }

.application-section__question {
  margin: 25px 0 0; }
  .application-section__question a, .application-section__question button {
    cursor: pointer;
    text-decoration: underline;
    color: #1C21DF !important; }
  .application-section__question .agreement-popup-section input[type="checkbox"] {
    width: 20px;
    height: 20px; }
  .application-section__question .agreement-popup-section .read-and-accept-message {
    margin-left: 10px;
    cursor: pointer; }
  @media (min-width: 769px) {
    .application-section__question {
      margin: 25px 0 0; } }
  .application-section__question .application-section__textarea .H6DesktopBlack > div {
    margin-top: 0px; }
    @media (max-width: 768.98px) {
      .application-section__question .application-section__textarea .H6DesktopBlack > div {
        color: #ffffff !important;
        position: -webkit-sticky !important; } }
  .application-section__question .field .application-field-label-style .dropdown-with-border > div {
    position: relative; }

.application-section__label {
  line-height: 1.5;
  color: #1a282c;
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 400;
  opacity: 0.6;
  letter-spacing: normal; }
  .application-section__label a {
    cursor: pointer;
    text-decoration: underline;
    color: #1E5558; }
  @media (min-width: 769px) {
    .application-section__label {
      opacity: 1; } }

.application-section__textarea {
  margin: 0; }

.application-section__textarea textarea {
  margin: 16px 0 0;
  padding: 16px 20px;
  height: 250px;
  font-size: 18px;
  letter-spacing: 0.31px;
  background-color: #ffffff; }
  @media (max-width: 768.98px) {
    .application-section__textarea textarea {
      padding: 32px; } }

.application-section__textarea.is-focused textarea {
  background-color: #ffffff;
  border-color: #1E5558; }

.application-section__textarea textarea::placeholder {
  color: #737373; }

.application-section__textarea.is-focused textarea::placeholder {
  color: #737373; }

.application-section__textarea .field__counter {
  color: #737373;
  font-size: 10px;
  letter-spacing: 0.92px;
  opacity: 1; }

.application-section__textarea.is-focused .field__counter {
  color: #1E5558; }

.application-section__mobile-done-wrapper {
  margin: auto 0 0;
  padding: 46px 0 0; }
  @media (min-width: 769px) {
    .application-section__mobile-done-wrapper {
      display: none; } }

.application-section__mobile-done {
  width: 100%;
  height: 56px;
  line-height: 56px;
  color: #ffffff;
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 400;
  letter-spacing: normal;
  background-color: #1C21DF;
  border-radius: 5px; }

.application-review-attachment {
  display: flex;
  align-items: center;
  padding: 24px 0;
  border-bottom: 1px solid #f6f6f6; }

.application-review-attachment__main .edit-user__btn {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 400;
  letter-spacing: normal;
  color: #1C21DF;
  border: 1px solid #1C21DF;
  border-radius: 5px;
  padding: 10px 15px 10px 15px;
  cursor: pointer; }

.application-review-attachment__heading {
  margin: 0;
  line-height: 1.5;
  color: #1a282c;
  font-family: 'Inter-Medium';
  font-size: 18px;
  font-weight: 400;
  letter-spacing: normal; }
  .application-review-attachment__heading pre {
    overflow-x: auto;
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;
    font-family: unset;
    line-height: 1.33; }

.application-review-attachment__small {
  display: block;
  line-height: 1.5;
  color: #737373;
  font-family: 'Inter-Medium';
  font-size: 14px; }
  .application-review-attachment__small pre {
    overflow-x: auto;
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;
    font-family: unset;
    line-height: 1.5; }

.application-review-attachment__trigger {
  padding: 5px 12px;
  cursor: pointer;
  color: #1C21DF;
  font-family: 'Inter-Regular';
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  border-radius: 5px;
  letter-spacing: normal;
  line-height: 1.5; }

.i-confirm-and-i-agree {
  display: flex; }
  .i-confirm-and-i-agree input[type="checkbox"] {
    margin-right: 15px;
    margin-top: 5px; }
  .i-confirm-and-i-agree .i-confirm-that-i-am {
    margin-bottom: 5px; }

#application-review-attachment__main-app {
  max-width: 400px; }
  @media (max-width: 768.98px) {
    #application-review-attachment__main-app {
      max-width: unset; } }
  @media (max-width: 479.98px) {
    #application-review-attachment__main-app {
      max-width: unset; } }

.encrypted-pencil-icon {
  cursor: pointer;
  padding-top: 5px;
  float: right; }
