.color__black {
  color: #1a282c; }

.color__white {
  color: #ffffff; }

.color__beige {
  color: #D9D9C9; }

.color__pink {
  color: #ffa3d1; }

.color__brown {
  color: #db7b1c; }

.color__light-blue {
  color: #98dde7; }

.color__blue {
  color: #1C21DF; }

.color__light-grey {
  color: #f6f6f6; }

.color__red {
  color: #FF542D; }

.color__green {
  color: #1E5558; }

.color__grey {
  color: #979797; }

.color__yellow {
  color: #ffcd00; }

.color__dark-grey {
  color: #737373; }

.color__soft-black {
  color: #364e55; }

.color__faint-grey {
  color: #f0f2f2; }

.color__wild-sand-grey {
  color: #e4e2df; }

.background-color__black {
  background-color: #1a282c; }

.background-color__white {
  background-color: #ffffff; }

.background-color__beige {
  background-color: #D9D9C9; }

.background-color__pink {
  background-color: #ffa3d1; }

.background-color__brown {
  background-color: #db7b1c; }

.background-color__light-blue {
  background-color: #98dde7; }

.background-color__blue {
  background-color: #1C21DF; }

.background-color__light-grey {
  background-color: #f6f6f6; }

.background-color__red {
  background-color: #FF542D; }

.background-color__green {
  background-color: #1E5558; }

.background-color__grey {
  background-color: #979797; }

.background-color__yellow {
  background-color: #ffcd00; }

.background-color__dark-grey {
  background-color: #737373; }

.background-color__soft-black {
  background-color: #364e55; }

.background-color__faint-grey {
  background-color: #f0f2f2; }

.background-color__wild-sand-grey {
  background-color: #e4e2df; }

.application-review-top {
  padding: 0 50px;
  height: 80px;
  background-color: #1a282c; }

.application-review-top__content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  width: 100%;
  max-width: 1340px;
  height: 100%; }

.application-review-top__left {
  display: flex;
  align-items: center;
  flex: 1 1 calc(100% / 3);
  height: 100%; }

.application-review-top__options-trigger {
  position: relative;
  display: block;
  padding: 0;
  height: 40px;
  width: 40px;
  background-color: #364e55;
  border-radius: 4px; }

.application-review-top__options-trigger.is-active {
  position: relative;
  background-color: #1C21DF; }

.application-review-top__options-trigger-triangle {
  display: none; }

.application-review-top__options-trigger.is-active
.application-review-top__options-trigger-triangle {
  content: "";
  position: absolute;
  left: 50%;
  bottom: -20px;
  transform: translateX(-50%);
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 10px 9px 10px;
  border-color: transparent transparent #ffffff transparent; }

.application-review-top__options-trigger::before,
.application-review-top__options-trigger::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 22px;
  height: 2px;
  background-color: #ffffff; }

.application-review-top__options-trigger::before {
  transform: translate(-50%, -50%) translateY(-5px); }

.application-review-top__options-trigger::after {
  transform: translate(-50%, -50%) translateY(5px); }

.application-review-top__options-trigger-icon {
  display: none; }

.application-review-top__options-trigger--active
.application-review-top__options-trigger-icon {
  content: "";
  position: absolute;
  top: -6px;
  right: -6px;
  display: block;
  width: 12px;
  height: 12px;
  background-color: #FF542D;
  border-radius: 50%; }

.application-review-top__count {
  display: block;
  margin: 0 0 0 20px;
  color: #ffffff;
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: 1.5; }

.application-review-top__title {
  flex: 1 1 calc(100% / 3);
  line-height: 1.5;
  color: #ffffff;
  font-family: 'Inter-Medium';
  font-size: 18px;
  font-weight: 400;
  letter-spacing: normal;
  text-align: center; }
  @media (max-width: 768.98px) {
    .application-review-top__title {
      text-align: left;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      display: -webkit-box;
      -webkit-box-orient: vertical; } }

.application-review-top__right {
  display: flex;
  justify-content: flex-end;
  flex: 1 1 calc(100% / 3);
  height: 100%; }

.application-review-top__exit-trigger {
  position: relative;
  right: -25px;
  display: block;
  padding: 0 25px;
  height: 100%;
  line-height: 1.5;
  color: #ffffff;
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal; }
