.applicant-home__content {
  max-width: 1145px;
  margin: 0 auto;
  padding: 0px 20px;
  min-height: calc(100vh - 68px); }
  .applicant-home__content .saved-application-page-title-section {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 32px; }
    @media (max-width: 768.98px) {
      .applicant-home__content .saved-application-page-title-section {
        flex-direction: column;
        margin-bottom: 1rem; } }
    .applicant-home__content .saved-application-page-title-section__description .H2DesktopGreen {
      margin-bottom: 32px; }
      @media (max-width: 768.98px) {
        .applicant-home__content .saved-application-page-title-section__description .H2DesktopGreen {
          margin-bottom: 10px; } }
    @media (max-width: 768.98px) {
      .applicant-home__content .saved-application-page-title-section__description .H5DesktopGreen {
        margin-bottom: 10px; } }
    .applicant-home__content .saved-application-page-title-section__applicant-new-message {
      display: flex;
      flex-direction: column;
      justify-content: center; }
      @media (max-width: 768.98px) {
        .applicant-home__content .saved-application-page-title-section__applicant-new-message {
          align-items: flex-start; } }
      .applicant-home__content .saved-application-page-title-section__applicant-new-message__top-section {
        margin-bottom: 10px; }
        @media (max-width: 768.98px) {
          .applicant-home__content .saved-application-page-title-section__applicant-new-message__top-section__great-job {
            font-size: 50px;
            line-height: normal; } }
        .applicant-home__content .saved-application-page-title-section__applicant-new-message__top-section__applicant-name {
          font-size: 50px; }
      .applicant-home__content .saved-application-page-title-section__applicant-new-message__middle-section {
        font-size: 12px; }
      .applicant-home__content .saved-application-page-title-section__applicant-new-message__bottom-section {
        font-size: 50px;
        margin-left: 15px; }
        @media (max-width: 768.98px) {
          .applicant-home__content .saved-application-page-title-section__applicant-new-message__bottom-section {
            line-height: normal; } }

.H5HeadingDivider {
  margin: 16px 0 41px 0px;
  border: dashed 1px #e6e9d7; }

.saved-application-page-title-section__right-status-box {
  display: flex; }
