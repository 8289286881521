#reviewer-applications-page {
  background: #ffffff; }
  #reviewer-applications-page .tabbed-table {
    padding-bottom: 80px; }
  #reviewer-applications-page .paginator__container {
    padding-bottom: 80px;
    background: #ffffff; }

#reviewer-applications-page__top {
  display: flex;
  justify-content: space-between;
  align-items: center; }
  @media (max-width: 768.98px) {
    #reviewer-applications-page__top {
      display: block; } }

#reviewer-applications-page__top-actions {
  display: flex;
  align-items: center;
  margin-right: 50px; }
  #reviewer-applications-page__top-actions.reviewer-applications-landing-page {
    align-items: flex-end; }
    #reviewer-applications-page__top-actions.reviewer-applications-landing-page .record-per-page-field-container .field .H6DesktopBlack {
      margin-top: 8px; }
    #reviewer-applications-page__top-actions.reviewer-applications-landing-page .record-per-page-field-container .field .H6DesktopBlack > div {
      background: #ffffff; }
    #reviewer-applications-page__top-actions.reviewer-applications-landing-page div[class$="-Control"] {
      background: #ffffff; }
    #reviewer-applications-page__top-actions.reviewer-applications-landing-page .field .field__text-field {
      height: 72px; }
    #reviewer-applications-page__top-actions.reviewer-applications-landing-page .field .field__search-icon {
      top: 33px; }
  #reviewer-applications-page__top-actions .field {
    margin: -6px 0 0 20px; }
    @media (max-width: 768.98px) {
      #reviewer-applications-page__top-actions .field {
        margin: 0px 0px 30px 30px; } }
    #reviewer-applications-page__top-actions .field .field__text-field {
      height: 58px;
      background-color: #ffffff;
      min-width: 297px; }

#reviewer-applications-page__top-copy {
  padding: 66px 0 44px 54px; }
  @media (max-width: 768.98px) {
    #reviewer-applications-page__top-copy {
      padding: 30px; } }

#reviewer-applications-page__greeting {
  margin-bottom: 14px; }
