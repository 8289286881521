.scholarship-cta {
  padding: 30px 30px 30px 30px;
  border-radius: 20px;
  max-width: 312px;
  box-sizing: border-box; }
  @media (max-width: 1023.98px) {
    .scholarship-cta {
      max-width: 250px; } }
  @media (max-width: 768.98px) {
    .scholarship-cta {
      height: unset;
      max-width: unset;
      border-radius: unset;
      padding: 40px 20px;
      min-height: unset; } }
  .scholarship-cta__text {
    text-transform: capitalize; }
  .scholarship-cta .cta-button {
    margin-top: 30px; }
