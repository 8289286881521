.applicant-profile__form .group:before {
  content: unset;
  display: unset; }

.applicant-profile__form .section.group {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px; }

.element-round-form-btn {
  margin-bottom: 30px; }

.create-round-button {
  margin-top: 30px !important; }
  .create-round-button .cta-button {
    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 4px 63px;
    gap: 10px;
    /* Primary Blue/blue-6 */
    background: #1C21DF;
    border-radius: 6px; }

.sso_checkbox {
  display: flex;
  align-items: center; }

.sso__checkbox {
  display: flex;
  align-items: center;
  padding: 0px 0px 36px 0px; }

.sso-primary-content {
  align-items: center;
  display: flex;
  gap: 10px; }
  .sso-primary-content .profile-field-column {
    flex: 1 1 auto; }

.sso-details-main-div .sso-details .sso_checkbox input[type="checkbox"] {
  margin-right: 10px; }

.sso-details-main-div .sso-feild-mappings .field-mapping-new-field {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 45px; }
  .sso-details-main-div .sso-feild-mappings .field-mapping-new-field .H5DesktopBlack {
    cursor: pointer;
    background: #1C21DF;
    color: #fff;
    padding: 10px 15px;
    border-radius: 5px;
    font-size: 14px; }

.field-mapping-button {
  background-color: #F7F7F3 !important;
  cursor: pointer !important;
  margin-bottom: 120px;
  margin-top: 50px;
  max-width: 78vw; }
  .field-mapping-button .field__text-field {
    background-color: #F7F7F3 !important;
    border: 2px solid #dfdfdf;
    cursor: pointer;
    max-width: 39vw !important; }

.field__mapping {
  display: flex !important;
  align-items: center;
  border: 1px solid black; }
  .field__mapping .feild-mapping-left {
    min-width: 77vw !important;
    display: flex;
    justify-content: space-between; }
    .field__mapping .feild-mapping-left .field__text-field {
      min-width: 37vw !important; }

.element-round-form-btn-submit {
  display: flex !important;
  justify-content: center; }

.tabs {
  align-items: center;
  margin-bottom: 20px;
  display: flex;
  padding-left: 10px; }
  .tabs .BodyDefaultRegularBlack {
    margin-top: 8px;
    margin-right: 20px; }

.tab {
  border: 1px solid #ccc;
  padding: 10px;
  cursor: pointer;
  border-radius: 5px;
  margin-right: 10px; }

.tab.active {
  background-color: #292fe9;
  color: white; }

.sso-new-field-content {
  display: flex;
  justify-content: space-between;
  margin-right: 60px; }

.add-new-field-column {
  box-sizing: border-box;
  width: 49%;
  border: 2px solid #dfdfdf;
  padding: 10px;
  margin: 5px;
  border-radius: 5px; }

.custom-field-sso {
  cursor: pointer; }
