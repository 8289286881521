.location-container {
  white-space: break-spaces !important;
  background-color: #1a282c !important;
  color: #ffffff !important;
  padding: 10px !important;
  border-radius: 8px !important;
  line-height: 25px !important;
  font-size: 17px !important;
  margin: 20px 0px !important;
  font-family: 'Inter-Medium', sans-serif !important; }
