.applicant-gradebook {
  overflow: auto;
  align-items: flex-start;
  max-width: 500px;
  z-index: 999; }
  @media (max-width: 768.98px) {
    .applicant-gradebook {
      max-width: calc(100% - 80px);
      top: 55%; } }
  .applicant-gradebook #gradebook-modal-content {
    width: 100%; }
    .applicant-gradebook #gradebook-modal-content #account-page__gpa .field__text-field {
      padding: 0 20px; }
    .applicant-gradebook #gradebook-modal-content #account-page__gpa .field__text-field.gpa {
      padding: 0 32px 0 83px; }
    .applicant-gradebook #gradebook-modal-content .field-group__field-full .H6DesktopBlack .css-8p0cwd-Control {
      padding: 0 15px; }
    .applicant-gradebook #gradebook-modal-content .gradebook-head_container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 15px; }
      .applicant-gradebook #gradebook-modal-content .gradebook-head_container .gradebook-head {
        cursor: pointer; }
    .applicant-gradebook #gradebook-modal-content .H5DesktopGreen {
      margin-bottom: 15px; }
    .applicant-gradebook #gradebook-modal-content .H5DesktopGreen:first-child {
      margin-bottom: 0px; }
    .applicant-gradebook #gradebook-modal-content .gradebook-document-download-container {
      display: flex;
      align-items: center;
      align-self: center; }
    .applicant-gradebook #gradebook-modal-content .gradebook-document-download-url {
      color: #1E5558; }
    .applicant-gradebook #gradebook-modal-content .react-tel-input {
      margin-top: 10px; }
      .applicant-gradebook #gradebook-modal-content .react-tel-input .field__tel-field {
        margin-left: 0;
        padding: 0 50px; }
      .applicant-gradebook #gradebook-modal-content .react-tel-input .flag-dropdown {
        border: 0px;
        background: #ffffff; }
        .applicant-gradebook #gradebook-modal-content .react-tel-input .flag-dropdown .selected-flag {
          width: 50px;
          padding: 0px 10px 0px 10px; }
    .applicant-gradebook #gradebook-modal-content .gradebook_submit_section {
      display: flex;
      align-items: center;
      justify-content: flex-end; }
      .applicant-gradebook #gradebook-modal-content .gradebook_submit_section #gradebook-page__cta__cancel {
        border: none;
        padding: 8px 20px; }
      .applicant-gradebook #gradebook-modal-content .gradebook_submit_section #gradebook-page__cta {
        padding: 8px 20px; }

.gradebook_instruction_for_student {
  width: 90%;
  min-height: 70px;
  background-color: white;
  padding: 10px 5px 10px;
  font-family: 'Inter-Medium';
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1a282c;
  overflow: auto; }
  .gradebook_instruction_for_student ul {
    list-style-type: disc;
    margin: 1em;
    margin-left: 40px; }
  .gradebook_instruction_for_student b {
    font-weight: bold; }
  .gradebook_instruction_for_student i {
    font-style: italic; }
  .gradebook_instruction_for_student u {
    text-decoration: underline; }
  .gradebook_instruction_for_student a {
    color: #1C21DF;
    text-decoration: underline; }
  .gradebook_instruction_for_student ol {
    list-style-type: auto;
    margin: 1em;
    margin-left: 40px; }
    .gradebook_instruction_for_student ol ol {
      list-style: lower-alpha; }
      .gradebook_instruction_for_student ol ol ol {
        list-style: lower-roman; }
  .gradebook_instruction_for_student table thead th {
    font-weight: bold; }
  @media only screen and (max-width: 600px) {
    .gradebook_instruction_for_student {
      width: 84%; } }

#layout-wrapper .applicant-gradebook {
  min-height: 40vh; }
  #layout-wrapper .applicant-gradebook .modal__inner #gradebook-modal-content .H6DesktopBlack.dropdown-with-border > div {
    position: relative; }

.gradebook-doc-field {
  font-weight: 600 !important; }

.gradebook-doc-upload-input {
  margin-bottom: 40px !important;
  margin-top: 10px !important; }
