.likert-question {
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none; }

.likert-question > .likert-question__item:not(:first-child) {
  margin: 0 0 0 8px; }

.likert-question__input {
  display: none; }

.likert-question__visualization {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  font-family: 'Inter-Medium';
  font-size: 18px;
  font-weight: 500;
  background-color: rgba(26, 40, 44, 0.05);
  border-radius: 50%;
  cursor: pointer;
  letter-spacing: normal; }

.likert-question__input:checked ~ .likert-question__visualization {
  color: #ffffff;
  background-color: #1C21DF; }
