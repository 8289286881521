.color__black {
  color: #1a282c; }

.color__white {
  color: #ffffff; }

.color__beige {
  color: #D9D9C9; }

.color__pink {
  color: #ffa3d1; }

.color__brown {
  color: #db7b1c; }

.color__light-blue {
  color: #98dde7; }

.color__blue {
  color: #1C21DF; }

.color__light-grey {
  color: #f6f6f6; }

.color__red {
  color: #FF542D; }

.color__green {
  color: #1E5558; }

.color__grey {
  color: #979797; }

.color__yellow {
  color: #ffcd00; }

.color__dark-grey {
  color: #737373; }

.color__soft-black {
  color: #364e55; }

.color__faint-grey {
  color: #f0f2f2; }

.color__wild-sand-grey {
  color: #e4e2df; }

.background-color__black {
  background-color: #1a282c; }

.background-color__white {
  background-color: #ffffff; }

.background-color__beige {
  background-color: #D9D9C9; }

.background-color__pink {
  background-color: #ffa3d1; }

.background-color__brown {
  background-color: #db7b1c; }

.background-color__light-blue {
  background-color: #98dde7; }

.background-color__blue {
  background-color: #1C21DF; }

.background-color__light-grey {
  background-color: #f6f6f6; }

.background-color__red {
  background-color: #FF542D; }

.background-color__green {
  background-color: #1E5558; }

.background-color__grey {
  background-color: #979797; }

.background-color__yellow {
  background-color: #ffcd00; }

.background-color__dark-grey {
  background-color: #737373; }

.background-color__soft-black {
  background-color: #364e55; }

.background-color__faint-grey {
  background-color: #f0f2f2; }

.background-color__wild-sand-grey {
  background-color: #e4e2df; }

.application-review-section.is-active:not(.application-review-section--last)::after {
  content: "";
  display: block;
  height: 65px; }

.application-review-section:not(.application-review-section--first) {
  margin: 24px 0 0; }

.application-review-section__top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 14px;
  border-bottom: 2px dotted #e6e9d7; }

.application-review-section__user-badge-with-heading {
  display: flex;
  align-items: center; }
  .application-review-section__user-badge-with-heading .user-badge {
    margin-right: 10px; }

.application-review-section__heading {
  margin: 0;
  line-height: 1.5;
  color: #1a282c;
  font-family: 'Inter-Medium';
  font-size: 18px;
  font-weight: 400;
  letter-spacing: normal;
  word-break: break-all; }
  .application-review-section__heading div a {
    color: #1C21DF;
    text-decoration: underline; }

.application-review-section.is-active .application-review-section__heading {
  color: #1a282c; }

.application-review-section__section-toggle {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 60px;
  min-height: 25px;
  line-height: 1.5;
  color: #1a282c;
  font-family: 'Inter-Medium';
  font-size: 18px;
  font-weight: 400;
  background-color: #f6f6f6;
  border-radius: 5px;
  padding: 10px;
  letter-spacing: normal; }

.application-review-section.is-active
.application-review-section__section-toggle {
  color: #ffffff;
  background-color: #1C21DF; }

.application-review-section__section-toggle::after {
  content: "";
  display: block;
  margin: 0 0 0 6px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6px 7px 0 7px;
  border-color: #1a282c transparent transparent transparent; }

.application-review-section.is-active
.application-review-section__section-toggle::after {
  border-color: #ffffff transparent transparent transparent; }

.application-review-section__content {
  display: none; }

.application-review-section.is-active .application-review-section__content {
  display: block; }
  .application-review-section.is-active .application-review-section__content .application-steps-preview-content-and-edit-button-container {
    display: flex;
    justify-content: space-between; }
    .application-review-section.is-active .application-review-section__content .application-steps-preview-content-and-edit-button-container .application-steps-preview-content {
      flex-grow: 1; }
      .application-review-section.is-active .application-review-section__content .application-steps-preview-content-and-edit-button-container .application-steps-preview-content .application-section__label {
        line-height: 1.5;
        color: #1a282c;
        font-family: 'Inter-Medium';
        font-size: 16px;
        font-weight: 400;
        padding-bottom: 10px;
        letter-spacing: normal; }

.application-steps-section-edit-button-container {
  display: flex;
  align-items: center;
  justify-content: flex-end; }
  .application-steps-section-edit-button-container__edit-button {
    color: #1a282c;
    font-family: 'Inter-Medium';
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: normal;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1.5;
    width: 70px;
    height: 25px;
    border-radius: 5px;
    background: #f6f6f6; }
    .application-steps-section-edit-button-container__edit-button__edit-icon {
      margin-right: 5px; }

.Update-Application-Documents-container {
  width: 1110px;
  margin: 0px auto; }
  .Update-Application-Documents-container .promote-user-menu {
    margin-bottom: 64px; }
    .Update-Application-Documents-container .promote-user-menu .promote-user {
      margin-top: 64px; }
      .Update-Application-Documents-container .promote-user-menu .promote-user .H5DesktopGreen {
        display: flex;
        align-items: center;
        justify-content: space-between; }
