.user-account-disbursement-page {
  max-width: 100%;
  margin: 0 auto;
  padding: 0px 20px; }
  .user-account-disbursement-page .event-head {
    margin-bottom: 0rem; }
  .user-account-disbursement-page .user-account-page-user-details-section {
    margin-bottom: 3.5rem;
    padding-top: 115px; }
    .user-account-disbursement-page .user-account-page-user-details-section .title-with-icon {
      display: flex;
      align-items: center;
      justify-content: space-between; }
      @media (max-width: 768.98px) {
        .user-account-disbursement-page .user-account-page-user-details-section .title-with-icon {
          flex-direction: column-reverse;
          text-align: center; } }
      @media (max-width: 768.98px) {
        .user-account-disbursement-page .user-account-page-user-details-section .title-with-icon svg {
          height: 80px; } }
      .user-account-disbursement-page .user-account-page-user-details-section .title-with-icon__description .H1DesktopGreen {
        color: #1a282c;
        font-weight: 600;
        font-size: 26px !important; }
      @media (max-width: 768.98px) {
        .user-account-disbursement-page .user-account-page-user-details-section .title-with-icon .H2DesktopGreen {
          margin-bottom: 20px; } }
    .user-account-disbursement-page .user-account-page-user-details-section .user-account-default-profile-image {
      width: 80px;
      height: 80px;
      background: #f6f6f6;
      border-radius: 100%;
      margin-right: 22px;
      display: flex;
      align-items: center;
      justify-content: center; }
      .user-account-disbursement-page .user-account-page-user-details-section .user-account-default-profile-image #user-account-page-user-icon {
        width: 41px; }
  .user-account-disbursement-page .section .Reset-Password {
    font-family: 'Inter-Medium';
    font-size: 16px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #1C21DF;
    line-height: 1.5; }

.H5HeadingDivider {
  margin: 16px 0 41px 0px;
  border: dashed 1px #e6e9d7; }

#applicant-screen__disbursements-table table thead tr th:first-child .table__column-header {
  justify-content: flex-start; }

#applicant-screen__disbursements-table table thead tr th .table__column-header {
  justify-content: flex-end; }

#applicant-screen__disbursements-table table tbody tr td:first-child {
  text-align: left; }
  #applicant-screen__disbursements-table table tbody tr td:first-child .scholarship-name-cell-type {
    justify-content: flex-start; }

#applicant-screen__disbursements-table table tbody tr td {
  text-align: right;
  max-width: 100%;
  min-width: fit-content; }

#applicant-screen__disbursements-table table tbody tr .table__row-menu-cell {
  justify-content: flex-end;
  display: flex; }

#applicant-screen__disbursements-table table tbody tr td:first-child {
  font-size: 18px !important;
  font-weight: 500; }

#applicant-screen__disbursements-table table tbody tr td .sponsor-tag-container .date-cell-type {
  display: block; }

#applicant-screen__donor-table {
  min-height: 80vh; }
