.application-header {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 5px;
  padding: 0 20px; }
  @media (max-width: 768.98px) {
    .application-header {
      display: unset; } }
  .application-header .header-buttion-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px; }
    .application-header .header-buttion-container .view-program-and-resume-button-section .back-to-resume-page-button-section {
      display: flex;
      text-align: center;
      align-items: center;
      margin-top: 10px; }
      .application-header .header-buttion-container .view-program-and-resume-button-section .back-to-resume-page-button-section .left-arrow-icon {
        height: 14px;
        width: 14px; }
      .application-header .header-buttion-container .view-program-and-resume-button-section .back-to-resume-page-button-section .back-to-resume-page-button {
        margin-left: 10px;
        color: #1E5558;
        font-size: 16px;
        font-family: 'Inter-Medium'; }
        @media only screen and (min-width: 770px) and (max-width: 1024px) {
          .application-header .header-buttion-container .view-program-and-resume-button-section .back-to-resume-page-button-section .back-to-resume-page-button {
            font-size: 13px; } }
    .application-header .header-buttion-container .save-application-button .cta-button {
      background-color: #ffffff;
      color: #1E5558;
      border: 1px solid #1E5558;
      padding: 8px 50px;
      height: 48px; }
      .application-header .header-buttion-container .save-application-button .cta-button__text {
        font-size: 14px; }
      .application-header .header-buttion-container .save-application-button .cta-button:hover {
        background-color: #e2e2e2; }
      .application-header .header-buttion-container .save-application-button .cta-button .cta-button__arrow {
        margin-left: 10px; }
        .application-header .header-buttion-container .save-application-button .cta-button .cta-button__arrow path {
          fill: #1E5558; }
    .application-header .header-buttion-container .save-application-button .save-application {
      margin-top: unset !important; }

.scholarship-name-application-header__heading {
  display: inline-block;
  line-height: 1.5;
  color: #1a282c;
  font-family: 'Inter-semiBold';
  font-size: 16px;
  letter-spacing: normal;
  margin-bottom: 20px; }
  @media (min-width: 769px) {
    .scholarship-name-application-header__heading {
      letter-spacing: unset;
      line-height: 1.5; } }

.application-header__heading-wrapper {
  display: flex;
  width: 100%;
  margin-right: 10px; }
  .application-header__heading-wrapper button {
    height: fit-content;
    padding: 8px 30px; }
  .application-header__heading-wrapper .application-header__actions {
    width: 30%;
    display: flex; }

.application-header__badge {
  display: flex;
  align-items: center;
  order: 2;
  margin: 0 0 0 auto;
  padding: 0 10px;
  height: 32px;
  line-height: 1.5;
  color: #1E5558;
  font-family: 'Inter-Medium';
  font-size: 10px;
  font-weight: 400;
  letter-spacing: normal;
  background-color: #f6f6f6;
  border-radius: 4px; }
  @media (min-width: 769px) {
    .application-header__badge {
      order: 3;
      margin: unset; } }

.application-header__badge-icon {
  display: block;
  margin: 0 3px 0 0; }

.application-header__badge-icon path {
  stroke: #1E5558; }

.application .application-left-side-header-content .application-header .program-logo-container {
  justify-content: center !important; }

.application .application-left-side-header-content .application-header .application-header__logo-wrapper .application-header__logo-container .application-header__logo {
  height: 150px;
  max-width: 100%;
  object-fit: contain;
  margin-bottom: 15px; }

.application .application-left-side-header-content .application-header .application-header__program-details {
  display: flex;
  width: 100%;
  flex-direction: column;
  font-size: 16px;
  font-family: 'Inter-Medium'; }
  .application .application-left-side-header-content .application-header .application-header__program-details .program-details__submitted-date {
    line-height: 24px;
    margin-bottom: 15px; }
  .application .application-left-side-header-content .application-header .application-header__program-details .program-details__view-detail {
    margin-top: 10px;
    line-height: 19px;
    display: flex;
    align-items: center; }
    .application .application-left-side-header-content .application-header .application-header__program-details .program-details__view-detail span {
      cursor: pointer; }
    .application .application-left-side-header-content .application-header .application-header__program-details .program-details__view-detail svg {
      width: 18px;
      height: 18px;
      position: relative;
      top: 2px;
      cursor: pointer; }
