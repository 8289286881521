.H1DesktopGreen {
  font-family: 'Inter-Light';
  font-size: 36px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  color: #1E5558; }

.H1DesktopBlack {
  font-family: 'Inter-Light';
  font-size: 36px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  color: #1a282c; }

.H1DesktopWhite {
  font-family: 'Inter-Light';
  font-size: 36px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  color: #f6f6f6; }

.H2Desktop {
  font-family: 'Inter-Medium';
  font-size: 32px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal; }

.H2DesktopGreen {
  font-family: 'Inter-Medium';
  font-size: 32px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.11;
  letter-spacing: normal;
  color: #1E5558; }

.DesktopGreen {
  font-family: 'Inter-Medium';
  font-size: 18px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: normal;
  color: #1E5558; }

.H2DesktopWhite {
  font-family: 'Inter-Medium';
  font-size: 32px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  color: #ffffff; }
  @media (max-width: 768.98px) {
    .H2DesktopWhite {
      font-size: 30px;
      line-height: 1.33; } }

.H3Desktop {
  font-family: 'Inter-Medium';
  font-size: 29px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal; }
  @media (max-width: 768.98px) {
    .H3Desktop {
      font-size: 24px;
      line-height: 2; } }

.H3BlackDesktop {
  font-family: 'Inter-Medium';
  font-size: 29px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1a282c; }

.H3WhiteDesktop {
  font-family: 'Inter-Medium';
  font-size: 29px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #ffffff; }

.H4DesktopGreen {
  font-family: 'Inter-Medium';
  font-size: 26px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1E5558; }

.H5DesktopGreen {
  font-family: 'Inter-Medium';
  font-size: 23px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1E5558; }

.H5DesktopGrey {
  font-family: 'Inter-Medium';
  font-size: 23px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #979797; }

.H5DesktopBlack {
  font-family: 'Inter-Medium';
  font-size: 23px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1a282c; }

.H6DesktopBlack {
  font-family: 'Inter-Medium';
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1a282c; }

.H4DesktopWhite {
  font-family: 'Inter-Medium';
  font-size: 26px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff; }

.H4DesktopBlack {
  font-family: 'Inter-Medium';
  font-size: 26px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1a282c; }

.H6Desktop {
  font-family: 'Inter-Medium';
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal; }

.H6DesktopGrey {
  font-family: 'Inter-Medium';
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #979797; }

.H6DesktopBlack {
  font-family: 'Inter-Medium';
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1a282c; }

.H6DesktopGreen {
  font-family: 'Inter-Medium';
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1E5558; }

.PDesktopGreen {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: black; }

.H6DesktopWhite {
  font-family: 'Inter-Medium';
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #ffffff; }

.BodyLargeWhite {
  font-family: 'Inter-Medium';
  font-size: 18px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: normal;
  color: #ffffff; }

.BodyDefault {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal; }

.BodyDefaultBold {
  font-family: 'Inter-Bold';
  font-size: 16px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal; }

.BodyDefaultBoldGrey {
  font-family: 'Inter-Bold';
  font-size: 16px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #979797; }

.BodyDefaultBoldWhite {
  font-family: 'Inter-Bold';
  font-size: 16px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #ffffff; }

.BodyDefaultBoldBlack {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1a282c; }

.BodyDefaultRegularGrey {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #979797; }

.BodyDefaultRegularBlack {
  font-family: 'Inter-Regular';
  font-size: 16px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1a282c; }

.BodySmallMediumGrey {
  font-family: 'Inter-Medium';
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #979797; }

.BodySmallMediumWhite {
  font-family: 'Inter-Medium';
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #ffffff; }

.BodySmallMediumBlack {
  font-family: 'Inter-Medium';
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1a282c; }

.BodySmallMedium {
  font-family: 'Inter-Medium';
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal; }

.CTA {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal; }

.CTABlue {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1C21DF; }

.CTAGrey, .submit-validation-page__content .thanks-for-submission-desription, .submit-validation-page__content .sharing-icons-submission-status, .submit-validation-page__content .help-email-address, .submit-validation-page__content .rating-box .rating-explanation {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1a282c; }

.CTAGreen {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1E5558; }

.CTABlack {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1a282c; }

.CTARed {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #FF542D; }

.CTAWhite {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #ffffff; }

.CTAYellow {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #ffcd00; }

.BodySmallRegular {
  font-family: 'Inter-Medium';
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal; }

.BodySmallRegularWhite {
  font-family: 'Inter-Medium';
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #ffffff; }

.BodySmallRegularGrey {
  font-family: 'Inter-Medium';
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #737373; }

.BodySmallRegularGreen {
  font-family: 'Inter-Medium';
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1E5558; }

.BodySmallRegularBlack {
  font-family: 'Inter-Medium';
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1a282c; }

.LabelGrey {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #737373; }

.LabelWhite {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #ffffff; }

.EyebrowBlack {
  font-family: 'Inter-Medium';
  font-size: 10px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1a282c;
  text-transform: uppercase; }

.EyebrowWhite {
  font-family: 'Inter-Medium';
  font-size: 10px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #ffffff;
  text-transform: uppercase; }

.EyebrowGreen {
  font-family: 'Inter-Medium';
  font-size: 10px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1E5558;
  text-transform: uppercase; }

.EyebrowGrey {
  font-family: 'Inter-Medium';
  font-size: 10px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #737373;
  text-transform: uppercase; }

.CTA-Anchor-Tag-css {
  display: flex;
  justify-content: center;
  width: 150px;
  height: 50px;
  color: #ffffff;
  background-color: #1C21DF;
  border: 1px solid #1C21DF;
  letter-spacing: normal;
  font-family: 'Inter-Medium', sans-serif;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  transition: transform 0.25s;
  border-radius: 5px;
  cursor: pointer; }
  .CTA-Anchor-Tag-css__Text {
    margin: auto;
    text-align: center;
    display: flex;
    align-items: center; }
    .CTA-Anchor-Tag-css__Text svg {
      margin-right: 12px;
      transform: rotate(180deg); }

.submit-validation-page__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px; }
  .submit-validation-page__header .submit-validation-title {
    text-transform: uppercase; }
  .submit-validation-page__header .submit-validation-sub-title {
    margin-bottom: 10px; }
  .submit-validation-page__header .close-btn {
    cursor: pointer; }

.submit-validation-page__content .thanks-for-submission-desription {
  margin-bottom: 10px;
  border: 1px dashed #D9D9C9;
  padding: 20px 10px;
  border-radius: 4px; }

.submit-validation-page__content .sharing-icons-submission-status {
  margin-bottom: 10px;
  border: 1px dashed #D9D9C9;
  padding: 20px 10px;
  border-radius: 4px; }
  .submit-validation-page__content .sharing-icons-submission-status .social-icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px; }
    .submit-validation-page__content .sharing-icons-submission-status .social-icon-container svg {
      border: 1px solid #D9D9C9;
      margin: 2px;
      border-radius: 4px; }

.submit-validation-page__content .help-email-address {
  margin-bottom: 10px;
  border: 1px dashed #D9D9C9;
  padding: 20px 10px;
  border-radius: 4px; }

.submit-validation-page__content .rating-box {
  border: 1px dashed #D9D9C9;
  padding: 20px 10px;
  border-radius: 4px; }
  .submit-validation-page__content .rating-box .rating-explanation {
    margin-bottom: 10px; }
    .submit-validation-page__content .rating-box .rating-explanation .rating-meter-min-max .rating-min {
      color: #FF542D; }
    .submit-validation-page__content .rating-box .rating-explanation .rating-meter-min-max .rating-max {
      color: #1E5558; }

.submit-validation-page__content .rating-values-container {
  display: flex;
  align-items: center; }
  @media (max-width: 768.98px) {
    .submit-validation-page__content .rating-values-container {
      align-items: unset;
      display: block; } }
  .submit-validation-page__content .rating-values-container .rating-individual-value {
    display: flex;
    margin-right: 15px; }

@media (max-width: 768.98px) {
  .submit-validation-page__content .option-checkbox {
    display: flex; } }

.submit-validation-page__content .option-checkbox .checkbox__input {
  display: grid;
  grid-template-areas: "checkbox"; }
  .submit-validation-page__content .option-checkbox .checkbox__input > * {
    grid-area: checkbox; }
  .submit-validation-page__content .option-checkbox .checkbox__input input[type="checkbox"] + .checkbox__control {
    border-radius: 4px;
    background: #ffffff; }
  .submit-validation-page__content .option-checkbox .checkbox__input input[type="radio"] + .checkbox__control {
    border-radius: 20px;
    background: #ffffff; }
    .submit-validation-page__content .option-checkbox .checkbox__input input[type="radio"] + .checkbox__control svg {
      border-radius: 20px !important; }
  .submit-validation-page__content .option-checkbox .checkbox__input input {
    opacity: 0;
    width: 20px;
    height: 20px;
    cursor: pointer; }
    .submit-validation-page__content .option-checkbox .checkbox__input input:hover + .checkbox__control {
      color: #1C21DF;
      border: 1px solid #1E5558;
      cursor: pointer; }
    .submit-validation-page__content .option-checkbox .checkbox__input input:focus + .checkbox__control {
      color: #1C21DF;
      border: 1px solid #1E5558;
      cursor: pointer; }
    .submit-validation-page__content .option-checkbox .checkbox__input input:checked + .checkbox__control svg {
      transform: scale(1);
      background: #1C21DF;
      color: #ffffff;
      border-radius: 3px;
      border: 2px solid #1C21DF;
      margin-left: -2px;
      margin-top: -2px; }
    .submit-validation-page__content .option-checkbox .checkbox__input input:disabled + .checkbox__control {
      color: #ffffff;
      background: #979797; }
  .submit-validation-page__content .option-checkbox .checkbox__input .checkbox__control {
    display: inline-grid;
    width: 20px;
    height: 20px;
    border-radius: 4px;
    border: 1px solid #1E5558;
    cursor: pointer; }
    .submit-validation-page__content .option-checkbox .checkbox__input .checkbox__control svg {
      transition: transform 0.1s ease-in 25ms;
      transform-origin: bottom left; }

.submit-validation-page__content .option-checkbox .checkbox-circle {
  display: inline-block;
  -webkit-border-radius: 50%;
  /* Safari 3-4, iOS 1-3.2, Android 1.6- */
  -moz-border-radius: 50%;
  /* Firefox 1-3.6 */
  border-radius: 50%;
  /* Opera 10.5, IE 9, Safari 5, Chrome, Firefox 4, iOS 4, Android 2.1+ */
  width: 20px;
  height: 20px;
  border: solid 1px #e4e2df;
  margin-right: 10px; }
  .submit-validation-page__content .option-checkbox .checkbox-circle .filter-selector-item:checked {
    background-color: #1C21DF; }

.submit-validation-page__content section {
  border: 1px dashed #e4e2df;
  border-radius: 5px;
  margin-bottom: 20px;
  padding: 10px; }
  .submit-validation-page__content section header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px dashed #e4e2df;
    margin-bottom: 10px;
    padding-bottom: 10px; }
    .submit-validation-page__content section header .submit-validation-section-title {
      line-height: 1.5;
      color: #979797;
      font-family: 'Inter-Medium';
      font-size: 23px;
      font-weight: 400;
      letter-spacing: normal; }
    .submit-validation-page__content section header .submit-validation-section-edit-btn {
      color: #737373;
      font-family: 'Inter-Medium';
      font-size: 16px;
      font-weight: 400;
      font-style: normal;
      letter-spacing: normal;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 70px;
      height: 40px;
      border-radius: 5px;
      background: #f6f6f6;
      line-height: 1.5; }
  .submit-validation-page__content section .config_input h4 {
    line-height: 1.5;
    color: #1a282c;
    font-family: 'Inter-Medium';
    font-size: 16px;
    font-weight: 400;
    display: flex;
    letter-spacing: normal; }
    .submit-validation-page__content section .config_input h4 .question-number {
      min-width: 30px !important;
      align-items: flex-start; }

.submit-validation-page__footer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px; }
