#reviewer-home-page {
  background: #ffffff; }
  #reviewer-home-page .paginator__container {
    padding-bottom: 80px;
    background: #ffffff; }
  #reviewer-home-page #reviewer-home-page__inner {
    background: #1E5558; }
    #reviewer-home-page #reviewer-home-page__inner .record-per-page-field-container__field .CTAGrey {
      color: #1a282c; }

#reviewer-home-page__top-copy {
  padding: 66px 44px 44px 54px; }
  @media (max-width: 1023.98px) {
    #reviewer-home-page__top-copy {
      padding: 40px 20px 40px 20px; } }

#reviewer-home-page__greeting {
  margin-bottom: 14px; }

#reviewer-home-page__greeting-icon {
  margin-right: 20px; }

#reviewer-home-page__top-container {
  display: flex;
  justify-content: space-between; }
  @media (max-width: 768.98px) {
    #reviewer-home-page__top-container {
      flex-direction: column; } }

.agreement-footer-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px; }
  .agreement-footer-btn #cta-btn-decline {
    margin-right: 10px; }
  .agreement-footer-btn #cta-btn-accept div {
    background-color: #1C21DF;
    padding: 20px;
    border-radius: 10px;
    color: #ffffff;
    font-family: 'Inter-Medium';
    font-size: 16px;
    cursor: pointer; }

.text-center {
  text-align: center; }

#task-modal-content .agreement-iframe {
  margin-bottom: 20px; }

#task-modal-content .agreement-iframe-top {
  margin-top: 20px; }

#task-modal-content .check-box-i-agree {
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px; }
  #task-modal-content .check-box-i-agree .form-check-input {
    margin-right: 10px;
    width: 30px;
    height: 30px; }
