.status-view {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 220;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #D9D9C9; }

.status-view--success {
  background-color: #ffa3d1; }

.status-view__score-alt-illustration {
  display: block;
  margin: 0 0 -4px;
  width: 100%;
  max-width: 122px;
  height: auto; }

.status-view__broken-pencil-illustration {
  display: block;
  width: 100%;
  max-width: 205px;
  height: auto; }

.status-view__eyes-illustration {
  display: block;
  width: 100%;
  max-width: 91px;
  height: auto; }

.status-view__heading {
  margin: 36px auto 0;
  width: 100%;
  max-width: 585px;
  line-height: 1.3;
  color: #1a282c;
  font-family: 'Inter-Medium';
  font-size: 36px;
  font-weight: 500;
  text-align: center;
  letter-spacing: normal; }
  @media (min-width: 769px) {
    .status-view__heading {
      margin: 22px auto 0;
      line-height: 60px;
      font-size: 54px; } }

.status-view__description {
  margin: 22px auto 0;
  width: 100%;
  max-width: 311px;
  line-height: 1.5;
  font-family: 'Inter-Medium';
  color: #1a282c;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: normal;
  text-align: center; }

.status-view__cta {
  margin: 115px auto 0;
  width: 100%; }
  @media (min-width: 769px) {
    .status-view__cta {
      width: auto;
      min-width: 191px; } }

.status-view__cta > .cta-button__text {
  width: 100%;
  text-align: center; }
