#info-banner {
  width: 100%;
  height: 72px;
  background-color: #1C21DF;
  color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 0 50px; }
  @media (max-width: 768.98px) {
    #info-banner {
      padding: 0 15px; } }

#info-banner__left {
  display: flex;
  align-items: center; }

#info-banner__icon {
  margin-right: 15px; }

.info-banner__text-item {
  margin-right: 8px; }
  .info-banner__text-item:last-child {
    margin-right: 0; }
