#recommender-home-page {
  min-height: calc(100vh - 80px);
  background: #ffffff; }
  @media (max-width: 768.98px) {
    #recommender-home-page__inner .tabbed-table__tab-container {
      display: flex;
      overflow-x: auto; } }
  @media (max-width: 768.98px) {
    #recommender-home-page__inner .tabbed-table__tab-container .tabbed-table__tab {
      min-width: 175px; } }
