.application-radio-buttons {
  margin: 4px 0 0;
  padding: 0;
  list-style: none; }
  .application-radio-buttons.jump-to-section .application-radio-buttons__visualization {
    min-width: 30px;
    border: none; }
  .application-radio-buttons.jump-to-section .application-radio-buttons__label {
    align-items: flex-start; }

.application-radio-buttons > .application-radio-buttons__item:not(:first-child) {
  margin: 24px 0 0; }

.application-radio-buttons__item {
  display: flex; }

.application-radio-buttons__label {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: center;
  line-height: 1.5;
  color: #1a282c;
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 400;
  letter-spacing: normal;
  cursor: pointer; }

.application-radio-buttons__input {
  display: none; }

.application-radio-buttons__visualization {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 15px 0 0;
  width: 30px;
  height: 30px;
  border: 1px solid  #f6f6f6;
  border-radius: 15px; }

.application-radio-buttons__input:checked ~ .application-radio-buttons__visualization {
  background-color: #1C21DF; }

.application-radio-buttons__icon {
  position: relative;
  lefT: 1px; }
