.donor-applications-landing-page {
  background-color: #F7F7F3;
  padding-top: 64px;
  margin: 0 auto;
  min-height: calc(100vh - 144px); }
  .donor-applications-landing-page #donor-scholarships-page__inner #donor-scholarships-page__top-container {
    margin: 0px 40px 15px 40px;
    align-items: center; }
    .donor-applications-landing-page #donor-scholarships-page__inner #donor-scholarships-page__top-container #donor-scholarships-page__top-actions {
      display: flex;
      justify-content: space-between; }
      .donor-applications-landing-page #donor-scholarships-page__inner #donor-scholarships-page__top-container #donor-scholarships-page__top-actions #donor-scholarships-page__greeting {
        margin: 0 0 0px 0px; }
      .donor-applications-landing-page #donor-scholarships-page__inner #donor-scholarships-page__top-container #donor-scholarships-page__top-actions .field {
        margin: 0px; }
      .donor-applications-landing-page #donor-scholarships-page__inner #donor-scholarships-page__top-container #donor-scholarships-page__top-actions .H6DesktopBlack > div {
        background: #ffffff; }
    .donor-applications-landing-page #donor-scholarships-page__inner #donor-scholarships-page__top-container #donor-scholarships-page__top-copy {
      margin-top: 15px;
      display: flex;
      justify-content: space-between; }
      .donor-applications-landing-page #donor-scholarships-page__inner #donor-scholarships-page__top-container #donor-scholarships-page__top-copy .record-per-page-field-container .record-per-page-field-container__field {
        margin: 0px; }
        .donor-applications-landing-page #donor-scholarships-page__inner #donor-scholarships-page__top-container #donor-scholarships-page__top-copy .record-per-page-field-container .record-per-page-field-container__field .CTAGrey .H6DesktopBlack .css-26l3qy-menu {
          z-index: 8; }
  .donor-applications-landing-page #donor-scholarships-page__inner div[class$="-Control"] {
    background: #ffffff; }
