#account-page {
  margin: 90px auto;
  max-width: 895px; }
  #account-page #account_logo_url__c .field__tip-message.show {
    z-index: 1; }
  #account-page #sponsor_image__c .field__tip-message.show {
    z-index: 1; }

#account-page__inner {
  margin: 0 20px; }
  #account-page__inner .field__color-hash {
    font-size: 24px; }

.error-login-with-donor {
  color: #FF542D;
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  margin-top: 15px;
  margin-bottom: 15px; }

#account-page__preferences {
  display: flex;
  flex-direction: column;
  margin-top: -40px; }
  #account-page__preferences .account-preference_description {
    color: #737373;
    font-style: italic;
    max-width: 80%; }
  #account-page__preferences .account-page__preference__text {
    width: 75%; }
  #account-page__preferences .account-page__preference__slider {
    width: 25%;
    text-align: right; }

.account-page__preference {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #f6f6f6;
  padding: 15px 0 20px; }

.Reset-Password {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1C21DF;
  position: relative;
  bottom: 35px; }

.image-upload-field__container {
  height: 87px;
  width: 87px;
  border-radius: 4px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 4px solid #ffffff;
  background: #f6f6f6;
  color: #ffffff; }
  .image-upload-field__container input[type="file"] {
    height: 95px;
    width: 95px;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 99;
    cursor: pointer; }
  .image-upload-field__container__icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: -8px;
    right: -8px;
    background: #1E5558;
    padding: 5px;
    border-radius: 100%; }
  .image-upload-field__container__avatar {
    width: 87px;
    height: 87px;
    object-fit: cover;
    border-radius: 4px; }

.primary-color-field .field__color-hash {
  top: 53px; }

.primary-color-field.errored .field__color-hash {
  bottom: 43px; }

.secondary-color-field .field__color-hash {
  bottom: 22px; }

.secondary-color-field.errored .field__color-hash {
  bottom: 41px; }

.description-rich-text-field .CTAGrey {
  font-style: unset; }
  .description-rich-text-field .CTAGrey .quill {
    margin-top: 8px;
    border: 1px solid #1E5558;
    border-radius: 4px; }
    .description-rich-text-field .CTAGrey .quill .ql-toolbar.ql-snow {
      border-top: 0px;
      border-radius: 4px; }
    .description-rich-text-field .CTAGrey .quill .ql-container.ql-snow {
      background: #ffffff;
      border-bottom: 0px;
      border-radius: 4px; }
      .description-rich-text-field .CTAGrey .quill .ql-container.ql-snow .ql-editor {
        min-height: 300px;
        background: #ffffff; }
        .description-rich-text-field .CTAGrey .quill .ql-container.ql-snow .ql-editor img {
          max-width: 100%; }
        .description-rich-text-field .CTAGrey .quill .ql-container.ql-snow .ql-editor ol {
          font-family: 'Inter-Medium';
          font-size: 18px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: normal;
          color: #1a282c; }
        .description-rich-text-field .CTAGrey .quill .ql-container.ql-snow .ql-editor ul {
          font-family: 'Inter-Medium';
          font-size: 18px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: normal;
          color: #1a282c; }
        .description-rich-text-field .CTAGrey .quill .ql-container.ql-snow .ql-editor p {
          font-family: 'Inter-Medium';
          font-size: 18px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: normal;
          color: #1a282c; }

#profile-page__cta {
  margin: auto;
  width: 150px;
  align-items: center;
  justify-content: center;
  height: 50px; }
