/** TODO: Add applications-page-specific styles here **/
#donor-applications-sidebar #donor-scholarships-page {
  margin-left: 238px;
  padding: 0 25px;
  overflow: hidden;
  background-color: #ffffff; }
  #donor-applications-sidebar #donor-scholarships-page .inner-applications {
    border: 1px solid gray;
    border-radius: 10px 10px 0px 0px;
    background-color: #F7F7F2; }
    #donor-applications-sidebar #donor-scholarships-page .inner-applications #donor-scholarships-page__top #donor-page-name-applications #donor-scholarships-page__top-actions {
      margin-top: 20px; }

#donor-applications-sidebar .scholarship-details .scholarship-details__content {
  max-width: calc(100vw - 256px) !important;
  margin-left: 238px; }
