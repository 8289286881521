.frontend-to-salesforce-sync-menu .frontend-to-salesforce-sync .header-container {
  display: flex;
  align-items: center;
  justify-content: space-between; }
  .frontend-to-salesforce-sync-menu .frontend-to-salesforce-sync .header-container .cta-button__text {
    display: flex;
    align-items: center; }
    .frontend-to-salesforce-sync-menu .frontend-to-salesforce-sync .header-container .cta-button__text svg {
      margin-right: 10px; }

.frontend-to-salesforce-sync-menu .frontend-to-salesforce-sync .admin-table__record-per-page {
  max-width: 170px;
  margin-left: auto; }
  .frontend-to-salesforce-sync-menu .frontend-to-salesforce-sync .admin-table__record-per-page .field {
    margin-bottom: 20px; }
  .frontend-to-salesforce-sync-menu .frontend-to-salesforce-sync .admin-table__record-per-page .record-per-page-field-container .field .CTAGrey .dropdown-with-border .css-26l3qy-menu {
    z-index: 6; }

.record_sfid {
  cursor: pointer;
  color: blue; }

.heroku-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 30px; }

.refresh_btn {
  display: flex; }
  .refresh_btn .toggle_section {
    margin-left: 10px !important; }

.H5DesktopGreen {
  display: flex;
  justify-content: space-between; }
  .H5DesktopGreen .toggle_section {
    justify-content: space-between; }

.refresh_btn_heroku {
  display: flex;
  justify-content: flex-end;
  margin-left: 10px; }
  .refresh_btn_heroku #filter-btn {
    position: relative;
    right: 16px; }

.salesforce-sync-margin .H5HeadingDivider {
  margin-bottom: 18px; }
