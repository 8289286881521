#donor-task-page {
  max-width: 1110px;
  margin: 64px auto; }
  #donor-task-page .notification-list__list .notification-list__item .task-notification-mark-as-complete-display {
    text-align: center; }
  #donor-task-page .notification-list__list .notification-list__item .scholarship-card__date {
    display: flex;
    align-items: center;
    border-top: solid 0px #f0f2f2; }

#donor-task-page__inner {
  margin: 0 20px; }

#donor-task-page__top {
  display: flex;
  margin-bottom: 0.5rem;
  justify-content: space-between;
  align-items: flex-start; }

#donor-task-page__bottom #donor-task-page__recent #donor-task-page__recent-list .notification-list__list {
  background: #ffffff;
  padding: 0px 20px 20px 20px; }

#task-list-return {
  margin-top: 20px;
  margin-bottom: 20px;
  float: right;
  border: 0px; }

.donor-add-new-task-btn-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 15px; }
  .donor-add-new-task-btn-container .donor-add-new-task-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center; }
  .donor-add-new-task-btn-container svg {
    margin-right: 15px; }
