.color__black {
  color: #1a282c; }

.color__white {
  color: #ffffff; }

.color__beige {
  color: #D9D9C9; }

.color__pink {
  color: #ffa3d1; }

.color__brown {
  color: #db7b1c; }

.color__light-blue {
  color: #98dde7; }

.color__blue {
  color: #1C21DF; }

.color__light-grey {
  color: #f6f6f6; }

.color__red {
  color: #FF542D; }

.color__green {
  color: #1E5558; }

.color__grey {
  color: #979797; }

.color__yellow {
  color: #ffcd00; }

.color__dark-grey {
  color: #737373; }

.color__soft-black {
  color: #364e55; }

.color__faint-grey {
  color: #f0f2f2; }

.color__wild-sand-grey {
  color: #e4e2df; }

.background-color__black {
  background-color: #1a282c; }

.background-color__white {
  background-color: #ffffff; }

.background-color__beige {
  background-color: #D9D9C9; }

.background-color__pink {
  background-color: #ffa3d1; }

.background-color__brown {
  background-color: #db7b1c; }

.background-color__light-blue {
  background-color: #98dde7; }

.background-color__blue {
  background-color: #1C21DF; }

.background-color__light-grey {
  background-color: #f6f6f6; }

.background-color__red {
  background-color: #FF542D; }

.background-color__green {
  background-color: #1E5558; }

.background-color__grey {
  background-color: #979797; }

.background-color__yellow {
  background-color: #ffcd00; }

.background-color__dark-grey {
  background-color: #737373; }

.background-color__soft-black {
  background-color: #364e55; }

.background-color__faint-grey {
  background-color: #f0f2f2; }

.background-color__wild-sand-grey {
  background-color: #e4e2df; }

.scorecard-fields {
  margin: 56px 0 0; }

.scorecard-fields__gpa-label {
  color: #1a282c;
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal; }
  .scorecard-fields__gpa-label label {
    color: #1a282c;
    font-family: 'Inter-Medium';
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal; }
  .scorecard-fields__gpa-label .field__dollar-sign {
    font-style: normal; }

.scorecard-fields__gpa {
  display: flex;
  align-items: center;
  margin: 8px 0 0;
  padding: 0 20px;
  height: 72px;
  color: #1a282c;
  font-family: 'Inter-Medium';
  font-size: 18px;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: normal;
  background-color: #ffffff;
  border-radius: 4px; }

.scorecard-fields__gpa-icon {
  margin: 0 8px 0 0;
  display: block;
  width: 32px;
  height: auto; }

.scorecard-fields__score {
  padding: 32px 0;
  border-top: 1px solid #cbcab4; }

.scorecard-fields__score--no-border {
  border-top: none; }

.scorecard-fields__score-heading {
  margin: 0 0 16px;
  line-height: 1.5;
  color: #1a282c;
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 400;
  letter-spacing: normal; }

.config-field-section-container__section-title {
  line-height: 1.5;
  color: #1a282c;
  font-family: 'Inter-Medium';
  font-size: 23px;
  padding: 0 0 15px;
  border-bottom: 1px dotted #1a282c;
  margin-bottom: 30px;
  font-weight: 500;
  letter-spacing: normal; }
