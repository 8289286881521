.application {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #ffffff; }
  @media (min-width: 769px) {
    .application {
      display: block;
      flex-direction: unset; } }
  @media (max-width: 768.98px) {
    .application {
      display: block;
      flex-direction: unset;
      top: 0px; } }
  @media (max-width: 479.98px) {
    .application {
      display: block;
      flex-direction: unset;
      top: 0px; } }
  .application .resume-page {
    padding: 60px 20px 20px 20px;
    display: flex;
    justify-content: space-between; }
    @media only screen and (max-width: 1024px) {
      .application .resume-page {
        padding: 40px 20px 20px 20px; } }
  .application .application__inner {
    border: 1px solid #1a282c;
    width: 80%;
    margin: 30px auto;
    border-radius: 12px 12px 12px 12px;
    position: relative;
    background-color: #ffffff; }
    @media (max-width: 768.98px) {
      .application .application__inner {
        width: 100%; } }
    @media (max-width: 479.98px) {
      .application .application__inner {
        width: 100%; } }

.application__content {
  display: flex;
  flex: 1 1 100%;
  flex-direction: column;
  margin: 0px 0px 20px 0px;
  width: 100%;
  max-width: 1145px;
  box-sizing: border-box; }
  @media (min-width: 769px) {
    .application__content {
      display: block;
      flex: unset;
      flex-direction: unset;
      max-width: unset; } }
  .application__content .application-header img {
    width: 260px;
    height: 150px;
    object-fit: contain; }
  @media (min-width: 769px) {
    .application__content {
      display: block;
      flex: unset;
      flex-direction: unset;
      max-width: unset; } }
  .application__content .application-review-sections {
    margin: 50px 0;
    padding: 0 20px; }
    .application__content .application-review-sections .application-review-section.is-active .application-review-section__content .application-review-text--first {
      margin: 25px 0 0; }
    .application__content .application-review-sections .application-review-section.is-active .application-review-section__content .application-review-text {
      margin: 25px 0 0; }
      .application__content .application-review-sections .application-review-section.is-active .application-review-section__content .application-review-text__heading {
        display: flex; }
      .application__content .application-review-sections .application-review-section.is-active .application-review-section__content .application-review-text .application-review-text__content {
        margin: 5px 0px 0px 30px; }
    .application__content .application-review-sections .application-review-section .application-review-section__top {
      align-items: flex-start !important; }

.application__form {
  margin: 0 0 32px; }
  @media (min-width: 769px) {
    .application__form {
      margin: unset; } }
  @media (max-width: 768.98px) {
    .application__form {
      margin: 0 0 0px; } }

.application__form > .application-section:first-child {
  margin: 32px 0 0; }

@media (max-width: 768.98px) {
  .submit-thanky-you-modal-popup {
    max-width: 80%;
    max-height: 60vh; } }

#body-inner {
  height: 100%; }
  #body-inner #layout-wrapper {
    height: 100%; }
    #body-inner #layout-wrapper .modal.submit-thanky-you-modal-popup {
      z-index: 999 !important;
      max-width: 60vw;
      width: unset; }

.modal-utility .sweet-alert {
  padding: 32px 32px 16px 32px !important;
  width: 400px !important;
  border: 1px solid #1a282c !important; }
  @media (min-width: 769px) {
    .modal-utility .sweet-alert {
      width: auto; } }
  .modal-utility .sweet-alert .content {
    padding: 0 18px;
    text-align: left; }
    .modal-utility .sweet-alert .content .CTAgrey {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px; }
  .modal-utility .sweet-alert .action-footer {
    width: 100%;
    display: flex;
    justify-content: right; }
    .modal-utility .sweet-alert .action-footer button {
      padding: 8px 30px;
      font-size: 16px;
      height: fit-content; }
  .modal-utility .sweet-alert h2 {
    margin: 0; }
  .modal-utility .sweet-alert .title {
    line-height: 24px;
    font-family: 'Inter-Regular', sans-serif;
    color: #434343;
    font-size: 16px;
    font-weight: 600;
    text-align: left;
    margin-bottom: 10px; }

button.cta-button.CTAWhite.inverse.arrow.transparent-CTAButton {
  background: #fff;
  border: 0px; }

button:focus-visible {
  outline: 2px solid #1E5558 !important; }

#application-page .resume-page {
  padding: 60px 20px 20px 20px;
  background-color: #FBFBF9;
  display: flex;
  justify-content: space-between; }
  @media only screen and (max-width: 1024px) {
    #application-page .resume-page {
      padding: 40px 20px 20px 20px; } }
  #application-page .resume-page .application-resume-left-side-header-content {
    width: 25%;
    inset: 100px 30px;
    position: absolute; }
    #application-page .resume-page .application-resume-left-side-header-content .left-side-content {
      background-color: #ffffff;
      width: 80%;
      border: 1px solid;
      border-radius: 5px;
      height: fit-content;
      padding: 15px;
      overflow: auto;
      max-height: 66vh; }
      #application-page .resume-page .application-resume-left-side-header-content .left-side-content::-webkit-scrollbar {
        display: block;
        width: 5px;
        color: #979797; }
      #application-page .resume-page .application-resume-left-side-header-content .left-side-content::-webkit-scrollbar-track {
        background: transparent; }
      #application-page .resume-page .application-resume-left-side-header-content .left-side-content::-webkit-scrollbar-thumb {
        background-color: #979797;
        border-radius: 2px; }
      #application-page .resume-page .application-resume-left-side-header-content .left-side-content .application-header {
        padding: unset !important; }
    @media (max-width: 479.98px) {
      #application-page .resume-page .application-resume-left-side-header-content {
        display: none; } }
    @media only screen and (min-width: 0px) and (max-width: 770px) {
      #application-page .resume-page .application-resume-left-side-header-content {
        display: none; } }
    @media only screen and (min-width: 769px) and (max-width: 1024px) {
      #application-page .resume-page .application-resume-left-side-header-content {
        position: relative;
        inset: 60px 0px; } }
  #application-page .resume-page #application-form-content-data {
    position: absolute;
    overflow: auto;
    width: 48%;
    inset: 70px;
    right: 22.5%;
    left: 25%; }
    @media only screen and (min-width: 0px) and (max-width: 1023px) {
      #application-page .resume-page #application-form-content-data {
        left: 27%;
        width: 70%; } }
    #application-page .resume-page #application-form-content-data::-webkit-scrollbar {
      display: block;
      width: 5px;
      color: #979797; }
    #application-page .resume-page #application-form-content-data::-webkit-scrollbar-track {
      background: transparent; }
    #application-page .resume-page #application-form-content-data::-webkit-scrollbar-thumb {
      background-color: #979797;
      border-right: none;
      border-left: none; }
  #application-page .resume-page .application-header .program-logo-container {
    justify-content: center !important; }
  #application-page .resume-page .application-header .application-header__logo-wrapper .application-header__logo-container .application-header__logo {
    height: 150px;
    max-width: 100%;
    object-fit: contain;
    margin-bottom: 15px; }
  #application-page .resume-page .application__content .application-header-content .program-logo-container {
    justify-content: space-between !important; }
