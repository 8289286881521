.main-heading-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px; }

.admin-sso .field__tip-icon {
  top: -10px !important; }

.sp-details {
  margin-top: 10px;
  margin-bottom: 15px; }

.sp-details-div {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px; }
  .sp-details-div .left-div {
    margin-right: 40px; }
  .sp-details-div span {
    margin-right: 20px;
    margin-left: 20px; }
  .sp-details-div .PDesktopGreen {
    padding-top: 10px;
    padding-bottom: 10px; }
  .sp-details-div .mid-div div {
    margin-right: 50px; }

.sso-admin-dropdown .field__tip-icon {
  position: absolute !important;
  top: -19px !important; }
