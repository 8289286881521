.application {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #ffffff; }
  @media (min-width: 769px) {
    .application {
      display: block;
      flex-direction: unset;
      padding: 0 0 64px; } }

.application__content {
  display: flex;
  flex: 1 1 100%;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  max-width: 1145px;
  box-sizing: border-box; }
  @media (min-width: 769px) {
    .application__content {
      display: block;
      flex: unset;
      flex-direction: unset; } }
  .application__content .application-review-sections .application-review-section.is-active .application-review-section__content .application-review-text--first {
    margin: 25px 0 0; }
  .application__content .application-review-sections .application-review-section.is-active .application-review-section__content .application-review-text {
    margin: 25px 0 0; }
    .application__content .application-review-sections .application-review-section.is-active .application-review-section__content .application-review-text__heading {
      display: flex; }
    .application__content .application-review-sections .application-review-section.is-active .application-review-section__content .application-review-text .application-review-text__content {
      margin: 5px 0px 0px 30px; }

.application__form {
  margin: 0 0 32px; }
  @media (min-width: 769px) {
    .application__form {
      margin: unset; } }
  @media (max-width: 768.98px) {
    .application__form {
      margin: 0 0 0px; } }

.application__form > .application-section:first-child {
  margin: 32px 0 0; }

.application__submit-trigger {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto auto 0;
  width: 100%;
  height: 56px;
  line-height: 56px;
  color: #1a282c;
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  background-color: #f6f6f6;
  border: none;
  border-radius: 5px; }
  @media (min-width: 769px) {
    .application__submit-trigger {
      display: inline-flex;
      margin: 80px 0 0;
      width: auto; } }

.application.is-completed .application__submit-trigger {
  color: #ffffff;
  background-color: #1C21DF; }

.application__submit-trigger-icon {
  display: inline-block;
  margin: 0 9px 0 0;
  width: 32px;
  height: auto; }

.application__submit-trigger-icon path {
  fill: #ffffff; }

.application__deadline {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 24px 0 19px;
  color: #1a282c;
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: 1.5; }
  @media (min-width: 769px) {
    .application__deadline {
      justify-content: flex-start;
      margin: 24px 0 0; } }

.application.is-completed .application__deadline {
  color: #1E5558; }

.application__deadline-icon {
  position: relative;
  top: -2px;
  display: block;
  margin: 0 11px 0 0;
  width: 32px;
  height: 32px; }

.multi-step-form-container {
  display: flex;
  justify-content: space-between;
  align-items: center; }
  @media (max-width: 479.98px) {
    .multi-step-form-container {
      margin: 15px !important; } }
  @media (max-width: 768.98px) {
    .multi-step-form-container {
      display: block !important;
      margin-top: 0px; } }
  @media (max-width: 768.98px) {
    .multi-step-form-container .multi-step-form-container__previous-step {
      margin-bottom: 10px; } }
  .multi-step-form-container .multi-step-form-container__previous-step .application__submit-trigger {
    margin: unset; }
  @media (max-width: 768.98px) {
    .multi-step-form-container .multi-step-form-container__step-current-status {
      margin-bottom: 10px;
      text-align: center; } }
  .multi-step-form-container .multi-step-form-container__step-current-status .application__submit-trigger {
    background: transparent;
    margin: unset; }
  .multi-step-form-container .multi-step-form-container__next-step .application__submit-trigger {
    margin: unset; }

.application-review.university-staff-review-detail-page .application-review__content {
  min-height: calc(100vh - 80px); }
