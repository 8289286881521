#login-page {
  margin: 80px 30px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }

#login-page__form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 600px;
  width: 100%; }
  #login-page__form .passwords-must-match {
    margin-bottom: 40px; }
    #login-page__form .passwords-must-match .list-items li {
      color: #FF542D; }
  #login-page__form .password-policy-list {
    margin-bottom: 40px; }
    #login-page__form .password-policy-list .list-items li {
      display: flex;
      align-items: center; }
      #login-page__form .password-policy-list .list-items li svg {
        margin-right: 5px; }
  #login-page__form .field {
    width: 100%; }

#login-code-page {
  margin-bottom: 36px; }
  #login-code-page #otp-divider {
    vertical-align: middle;
    color: #e4e2df;
    font-size: 2rem;
    margin: 1rem 1rem;
    line-height: 2.5rem; }
  #login-code-page input {
    border: 1px solid #e4e2df;
    padding: 10px; }
  #login-code-page input:focus {
    outline: 2;
    outline: auto;
    outline-color: #1E5558; }
  #login-code-page input:nth-child(6),
  #login-code-page input:nth-child(2) {
    border-left: none;
    border-right: none; }
    #login-code-page input:nth-child(6):focus,
    #login-code-page input:nth-child(2):focus {
      border-style: groove; }
  #login-code-page input:last-child,
  #login-code-page input:nth-child(3) {
    border-radius: 0 10px 10px 0; }
  #login-code-page input:first-child,
  #login-code-page input:nth-child(5) {
    border-radius: 10px 0 0 10px; }

#login-page__title {
  margin-bottom: 48px; }
  #login-page__title.reset-password-title {
    margin-bottom: 0px; }

#login-page__bottom {
  display: flex;
  justify-content: space-evenly;
  width: 300px;
  margin: 150px auto; }

#login-page__instructions {
  background-color: #F7F7F3;
  padding: 20px;
  max-width: 427px;
  width: 100%;
  margin: 24px auto;
  text-align: center; }
  @media (max-width: 1023.98px) {
    #login-page__instructions {
      max-width: 275px; } }

#login-page__instructions-cta {
  text-decoration: underline; }

#login-page__cta {
  width: 150px;
  display: flex;
  justify-content: center;
  margin-bottom: 100px; }

#login-code-page {
  margin: 30px 30px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }

#login-code-page__sub-title {
  text-align: center;
  margin-top: 16px;
  margin-bottom: 32px; }

#login-code-page__instructions {
  padding: 20px;
  max-width: 427px;
  width: 100%;
  margin: 24px auto;
  text-align: center; }

#login-code-page__eyes {
  margin-bottom: 36px; }
