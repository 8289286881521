.application-header {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 5px;
  padding: 0 20px; }
  @media (max-width: 768.98px) {
    .application-header {
      display: unset; } }
  .application-header__logo-wrapper {
    width: auto;
    display: flex;
    justify-content: space-between;
    align-items: center; }
    @media (max-width: 768.98px) {
      .application-header__logo-wrapper {
        margin-top: 10px; } }
    .application-header__logo-wrapper .application-header__print-container .application-final-review-header__print-trigger {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 122px;
      height: 56px;
      line-height: 1;
      color: #1a282c;
      font-family: 'Inter-Medium';
      font-size: 16px;
      font-weight: 400;
      font-style: normal;
      letter-spacing: normal; }
      .application-header__logo-wrapper .application-header__print-container .application-final-review-header__print-trigger__print-icon {
        margin: 0 8px 0 0; }
    @media (min-width: 769px) {
      .application-header__logo-wrapper {
        margin: 0 0 16px 0;
        width: 100%; } }
  .application-header__heading-wrapper .scholarship-name-application-header__heading {
    width: 60%;
    display: inline-block;
    line-height: 1.5;
    color: #1a282c;
    font-family: 'Inter-semiBold';
    font-size: 16px;
    letter-spacing: normal;
    margin-bottom: 20px; }
    @media (min-width: 769px) {
      .application-header__heading-wrapper .scholarship-name-application-header__heading {
        letter-spacing: unset;
        line-height: 1.5; } }
    @media only screen and (max-width: 480px) {
      .application-header__heading-wrapper .scholarship-name-application-header__heading {
        width: 100%; } }
  .application-header__program-details {
    display: flex;
    width: 100%;
    flex-direction: column;
    font-size: 16px;
    font-family: 'Inter-Medium'; }
    .application-header__program-details .program-details__submitted-date {
      line-height: 24px;
      margin-bottom: 15px; }
      @media only screen and (max-width: 770px) {
        .application-header__program-details .program-details__submitted-date {
          margin-bottom: unset; } }
  .application-header .summery-page-review-text {
    margin-top: 5px;
    width: 60%; }
    .application-header .summery-page-review-text ul {
      list-style-type: disc;
      margin: 1em;
      margin-left: 40px; }
    .application-header .summery-page-review-text b {
      font-weight: bold; }
    .application-header .summery-page-review-text i {
      font-style: italic; }
    .application-header .summery-page-review-text u {
      text-decoration: underline; }
    .application-header .summery-page-review-text a {
      color: #1C21DF;
      text-decoration: underline; }
    .application-header .summery-page-review-text ol {
      list-style-type: auto;
      margin: 1em;
      margin-left: 40px; }
      .application-header .summery-page-review-text ol ol {
        list-style: lower-alpha; }
        .application-header .summery-page-review-text ol ol ol {
          list-style: lower-roman; }
    .application-header .summery-page-review-text table thead th {
      font-weight: bold; }
    @media only screen and (max-width: 770px) {
      .application-header .summery-page-review-text {
        margin-top: 10px;
        width: 100%; } }
  .application-header .header-buttion-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px; }
    @media only screen and (max-width: 770px) {
      .application-header .header-buttion-container {
        margin-top: unset; } }
    .application-header .header-buttion-container .view-program-and-resume-button-section .back-to-resume-page-button-section {
      display: flex;
      text-align: center;
      align-items: center;
      margin-top: 10px; }
      .application-header .header-buttion-container .view-program-and-resume-button-section .back-to-resume-page-button-section .left-arrow-icon {
        height: 14px;
        width: 14px; }
      .application-header .header-buttion-container .view-program-and-resume-button-section .back-to-resume-page-button-section .back-to-resume-page-button {
        margin-left: 10px;
        color: #1E5558;
        font-size: 16px;
        font-family: 'Inter-Medium'; }
        @media only screen and (min-width: 770px) and (max-width: 1024px) {
          .application-header .header-buttion-container .view-program-and-resume-button-section .back-to-resume-page-button-section .back-to-resume-page-button {
            font-size: 13px; } }
    .application-header .header-buttion-container .save-application-button .cta-button {
      background-color: #ffffff;
      color: #1E5558;
      border: 1px solid #1E5558;
      padding: 8px 50px;
      height: 48px; }
      .application-header .header-buttion-container .save-application-button .cta-button__text {
        font-size: 14px; }
      .application-header .header-buttion-container .save-application-button .cta-button:hover {
        background-color: #e2e2e2; }
      .application-header .header-buttion-container .save-application-button .cta-button .cta-button__arrow {
        margin-left: 10px; }
        .application-header .header-buttion-container .save-application-button .cta-button .cta-button__arrow path {
          fill: #1E5558; }

.application-header__heading-wrapper {
  display: flex;
  width: 100%;
  line-height: 1.5;
  margin-bottom: 10px; }
  @media (max-width: 768.98px) {
    .application-header__heading-wrapper {
      line-height: 1.5; } }
  @media (max-width: 479.98px) {
    .application-header__heading-wrapper {
      display: block;
      line-height: 1.5; } }
  .application-header__heading-wrapper .application-header__heading {
    display: inline-block;
    width: 65%;
    line-height: 1.5;
    color: #1a282c;
    font-family: 'Inter-Medium';
    font-size: 24px;
    font-weight: 500;
    letter-spacing: normal; }
    @media (min-width: 769px) {
      .application-header__heading-wrapper .application-header__heading {
        line-height: 1.5;
        font-size: 24px;
        letter-spacing: unset; } }
    @media (max-width: 479.98px) {
      .application-header__heading-wrapper .application-header__heading {
        font-size: 20px;
        width: 100%;
        display: unset; } }
  .application-header__heading-wrapper .application-submit-resume-action-button-container {
    justify-content: flex-end;
    display: grid;
    margin-left: auto;
    max-height: 90px; }
    .application-header__heading-wrapper .application-submit-resume-action-button-container button {
      font-size: 14px; }
    @media (max-width: 479.98px) {
      .application-header__heading-wrapper .application-submit-resume-action-button-container {
        width: unset; } }
    .application-header__heading-wrapper .application-submit-resume-action-button-container .application-resume-action-button {
      color: #1C21DF;
      padding: 7px 0px;
      margin-bottom: 5px; }
      .application-header__heading-wrapper .application-submit-resume-action-button-container .application-resume-action-button span {
        font-size: 14px;
        display: flex; }
      .application-header__heading-wrapper .application-submit-resume-action-button-container .application-resume-action-button .cta-button__arrow {
        width: 18px;
        height: 18px;
        margin-left: 5px !important; }
        .application-header__heading-wrapper .application-submit-resume-action-button-container .application-resume-action-button .cta-button__arrow path {
          fill: #1C21DF; }
    .application-header__heading-wrapper .application-submit-resume-action-button-container .application-submit-action-button {
      padding: 10px 20px;
      font-size: 16px;
      text-align: center;
      justify-content: center;
      background-color: #1C21DF;
      color: #fff;
      border: none;
      border-radius: 5px;
      position: relative;
      overflow: hidden;
      cursor: pointer;
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
      transition: transform 0.3s ease, box-shadow 0.3s ease; }
    .application-header__heading-wrapper .application-submit-resume-action-button-container .application-submit-action-button:hover {
      transform: translateY(-2px);
      box-shadow: 0 8px 20px rgba(0, 0, 0, 0.5); }
    .application-header__heading-wrapper .application-submit-resume-action-button-container .application-submit-action-button::before {
      content: '';
      position: absolute;
      top: 0;
      left: -50%;
      width: 200%;
      height: 200%;
      background: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0.15) 75%);
      animation: shimmer 2s infinite linear; }

@keyframes shimmer {
  0% {
    left: -100%; }
  100% {
    left: 100%; } }

.application-header__badge {
  display: flex;
  align-items: center;
  margin: 0 0 0 auto;
  padding: 0 10px;
  height: 32px;
  line-height: 1.5;
  color: #1E5558;
  font-family: 'Inter-medium';
  font-size: 10px;
  font-weight: 400;
  letter-spacing: normal;
  background-color: #f6f6f6;
  border-radius: 4px; }
  @media (min-width: 769px) {
    .application-header__badge {
      margin: unset; } }

.application-header__badge-icon {
  display: block;
  margin: 0 3px 0 0; }

.application-header__badge-icon path {
  stroke: #1E5558; }

button.cta-button.CTAWhite.inverse.arrow.transparent-CTAButton {
  background: #fff;
  border: 0px; }
