#donor-scholarships-page {
  background-color: #F7F7F3;
  padding-top: 64px;
  margin: 0 auto; }
  #donor-scholarships-page__inner .tabbed-table .table__container table {
    margin-bottom: 115px; }
  #donor-scholarships-page .paginator__container {
    padding-bottom: 0px; }
  #donor-scholarships-page #APPLICATIONS {
    padding-bottom: 0px; }

#donor-scholarships-page__top-actions {
  display: flex;
  align-items: flex-end !important; }
  #donor-scholarships-page__top-actions .donor-page_reports-bar {
    flex-direction: column; }
  #donor-scholarships-page__top-actions .record-per-page-field-container {
    margin-top: 15px; }
  @media (max-width: 768.98px) {
    #donor-scholarships-page__top-actions .cta-button.with-icon {
      margin-bottom: 15px; } }
  @media (max-width: 768.98px) {
    #donor-scholarships-page__top-actions {
      flex-direction: column; } }
  #donor-scholarships-page__top-actions .field {
    margin-bottom: 0px;
    margin-left: 10px; }
    #donor-scholarships-page__top-actions .field .field__text-field {
      background-color: #ffffff; }
      #donor-scholarships-page__top-actions .field .field__text-field.search {
        min-width: 290px; }
    #donor-scholarships-page__top-actions .field .field__search-icon {
      top: 22px; }

#donor-scholarships-page__top {
  margin-bottom: 15px;
  justify-content: space-between;
  align-items: flex-start; }
  @media (max-width: 768.98px) {
    #donor-scholarships-page__top {
      flex-direction: column;
      margin: 0px 20px 40px 20px;
      align-items: center; } }
  #donor-scholarships-page__top .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    /* Adjust opacity as needed */
    z-index: 1000;
    /* Adjust z-index as needed */
    display: flex;
    justify-content: center;
    align-items: center; }
    #donor-scholarships-page__top .modal-overlay .modal {
      border: 1px solid gray; }
      #donor-scholarships-page__top .modal-overlay .modal .modal__inner {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%; }
        #donor-scholarships-page__top .modal-overlay .modal .modal__inner .update-status-page {
          width: 100%; }
          #donor-scholarships-page__top .modal-overlay .modal .modal__inner .update-status-page__header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 15px; }
            #donor-scholarships-page__top .modal-overlay .modal .modal__inner .update-status-page__header .update-status-sub-title {
              margin-bottom: 10px; }
            #donor-scholarships-page__top .modal-overlay .modal .modal__inner .update-status-page__header .close-btn {
              cursor: pointer; }
          #donor-scholarships-page__top .modal-overlay .modal .modal__inner .update-status-page__content {
            margin-top: 10px; }
          #donor-scholarships-page__top .modal-overlay .modal .modal__inner .update-status-page__footer {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 0px; }
        #donor-scholarships-page__top .modal-overlay .modal .modal__inner .applicant-task__submit {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 15px;
          margin-bottom: 15px; }

#donor-scholarships-page__top.donor-scholarships-page__top--equal-side-margins {
  margin: 0 40px 40px 40px; }

#donor-scholarships-page__top.donor-scholarships-page__top--align-bottom {
  align-items: flex-end; }

#donor-scholarships-page__greeting {
  margin: 0 0 20px 50px; }
  @media (max-width: 768.98px) {
    #donor-scholarships-page__greeting {
      margin: 0 0 20px 0px; } }

#donor-scholarships-page__greeting.donor-scholarships-application-page__greeting {
  margin: 18px 10px 10px 10px;
  font-family: 'Inter-Regular';
  color: rgba(0, 0, 0, 0.85);
  font-weight: 400;
  font-size: 32px;
  line-height: 1.35; }

#layout-wrapper #donor-applications-sidebar #donor-scholarships-page #donor-scholarships-page__inner #donor-scholarships-page__top .modal-overlay .modal {
  max-height: 96vh; }
  #layout-wrapper #donor-applications-sidebar #donor-scholarships-page #donor-scholarships-page__inner #donor-scholarships-page__top .modal-overlay .modal .modal__inner .update-status-page .H6DesktopBlack.dropdown-with-border > div {
    position: relative; }
