.color__black {
  color: #1a282c; }

.color__white {
  color: #ffffff; }

.color__beige {
  color: #D9D9C9; }

.color__pink {
  color: #ffa3d1; }

.color__brown {
  color: #db7b1c; }

.color__light-blue {
  color: #98dde7; }

.color__blue {
  color: #1C21DF; }

.color__light-grey {
  color: #f6f6f6; }

.color__red {
  color: #FF542D; }

.color__green {
  color: #1E5558; }

.color__grey {
  color: #979797; }

.color__yellow {
  color: #ffcd00; }

.color__dark-grey {
  color: #737373; }

.color__soft-black {
  color: #364e55; }

.color__faint-grey {
  color: #f0f2f2; }

.color__wild-sand-grey {
  color: #e4e2df; }

.background-color__black {
  background-color: #1a282c; }

.background-color__white {
  background-color: #ffffff; }

.background-color__beige {
  background-color: #D9D9C9; }

.background-color__pink {
  background-color: #ffa3d1; }

.background-color__brown {
  background-color: #db7b1c; }

.background-color__light-blue {
  background-color: #98dde7; }

.background-color__blue {
  background-color: #1C21DF; }

.background-color__light-grey {
  background-color: #f6f6f6; }

.background-color__red {
  background-color: #FF542D; }

.background-color__green {
  background-color: #1E5558; }

.background-color__grey {
  background-color: #979797; }

.background-color__yellow {
  background-color: #ffcd00; }

.background-color__dark-grey {
  background-color: #737373; }

.background-color__soft-black {
  background-color: #364e55; }

.background-color__faint-grey {
  background-color: #f0f2f2; }

.background-color__wild-sand-grey {
  background-color: #e4e2df; }

.scorecard-header__heading {
  margin: 0;
  line-height: 1.5;
  color: #1a282c;
  font-family: 'Inter-Medium';
  font-size: 29px;
  font-weight: 500;
  letter-spacing: normal; }

.scorecard-header__icon {
  position: relative;
  top: 4px;
  display: inline-block;
  margin: 0 0 0 15px;
  width: 52px;
  height: auto; }

.scorecard-header__description {
  margin: 24px 0 0;
  line-height: 1.5;
  color: #1a282c;
  font-family: 'Inter-Medium';
  font-size: 14px;
  font-weight: 400;
  letter-spacing: normal;
  word-break: break-word; }
  .scorecard-header__description a {
    text-decoration: underline;
    font-weight: bold; }

.scorecard-header__link {
  font-weight: 500;
  text-decoration: underline; }
