.application {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #ffffff; }
  @media (min-width: 769px) {
    .application {
      display: block;
      flex-direction: unset; } }
  @media (max-width: 768.98px) {
    .application {
      display: block;
      flex-direction: unset;
      top: 0px; } }
  @media (max-width: 479.98px) {
    .application {
      display: block;
      flex-direction: unset;
      top: 0px; } }
  .application .application_resume {
    padding: 100px 20px 20px 20px; }
    @media only screen and (max-width: 1024px) {
      .application .application_resume {
        padding: 40px 20px 20px 20px; } }
  .application .application__inner {
    border: 1px solid #1a282c;
    width: 80%;
    margin: 30px auto;
    margin-bottom: 50px;
    border-radius: 12px 12px 12px 12px;
    position: relative;
    background-color: #ffffff; }
    @media (max-width: 768.98px) {
      .application .application__inner {
        width: 100%; } }
    @media (max-width: 479.98px) {
      .application .application__inner {
        width: 100%; } }
  .application #alert-countries_model .sweet-alert .CTAWhite {
    padding: 8px 20px; }
  .application #payment-confirmation-alert .sweet-alert .CTAWhite {
    padding: 8px 20px; }

.save-changes-confirmation-alert p {
  flex-direction: row-reverse; }

.application__content {
  display: flex;
  flex: 1 1 100%;
  flex-direction: column;
  margin: 0px 0px 20px 0px;
  width: 100%;
  max-width: 1145px;
  box-sizing: border-box; }
  @media (min-width: 769px) {
    .application__content {
      display: block;
      flex: unset;
      flex-direction: unset;
      max-width: unset; } }
  .application__content .application-header img {
    width: 260px;
    height: 150px;
    object-fit: contain; }

.application__form {
  margin: 0 0 32px; }
  @media (min-width: 769px) {
    .application__form {
      margin: unset; } }
  @media (max-width: 768.98px) {
    .application__form {
      margin: 0 0 0px; } }

.application__form > .application-section:first-child {
  margin: 52px 0 0; }
  @media (min-width: 769px) {
    .application__form > .application-section:first-child {
      margin: 52px 0 0; } }

.application__submit-trigger {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto auto 0;
  width: 100%;
  height: 56px;
  line-height: 56px;
  color: #1a282c;
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  background-color: #f6f6f6;
  border: none;
  border-radius: 5px; }
  @media (min-width: 769px) {
    .application__submit-trigger {
      display: inline-flex !important;
      margin: 30px 0 0;
      width: auto; } }

.application.is-completed .application__submit-trigger {
  color: #ffffff;
  background-color: #1C21DF; }

.application__submit-trigger-icon {
  display: inline-block;
  margin: 0 9px 0 0;
  width: 32px;
  height: auto; }

.application__submit-trigger-icon path {
  fill: #ffffff; }

.application.is-completed .application__deadline {
  color: #1E5558; }

.application__deadline-icon {
  position: relative;
  top: -2px;
  display: block;
  margin: 0 11px 0 0;
  width: 32px;
  height: 32px; }

#recommender__create .modal__inner #task-modal-content {
  width: 100%; }
  #recommender__create .modal__inner #task-modal-content .user-task-add__form .message-for-recommender {
    color: #FF542D;
    font-family: 'Inter-Medium';
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: normal;
    margin-top: 15px;
    margin-bottom: 15px;
    line-height: 1.5; }
  #recommender__create .modal__inner #task-modal-content .user-task-add__form .applicant-task__submit #task-page__cta__cancel {
    margin-right: 10px; }

.applicant-right-side__content-area.side-drawer-menu-large-space #Esign_docusign_create {
  padding: 0px;
  max-width: 60vw;
  border: 1px solid #D9D9C9;
  left: calc(60%); }
  .applicant-right-side__content-area.side-drawer-menu-large-space #Esign_docusign_create .task-head_container {
    margin-top: 15px;
    margin-left: 15px;
    margin-right: 15px;
    justify-content: flex-end; }
    .applicant-right-side__content-area.side-drawer-menu-large-space #Esign_docusign_create .task-head_container .event-head {
      cursor: pointer; }
  @media (max-width: 768.98px) {
    .applicant-right-side__content-area.side-drawer-menu-large-space #Esign_docusign_create {
      max-width: calc(100% - 40px);
      left: calc(50%);
      top: 55%;
      z-index: 999999999999 !important; } }

.applicant-right-side__content-area.side-drawer-menu-small-space #Esign_docusign_create {
  padding: 0px;
  max-width: 60vw;
  border: 1px solid #D9D9C9; }
  .applicant-right-side__content-area.side-drawer-menu-small-space #Esign_docusign_create .task-head_container {
    margin-top: 15px;
    margin-left: 15px;
    margin-right: 15px;
    justify-content: flex-end; }
    .applicant-right-side__content-area.side-drawer-menu-small-space #Esign_docusign_create .task-head_container .event-head {
      cursor: pointer; }
  @media (max-width: 768.98px) {
    .applicant-right-side__content-area.side-drawer-menu-small-space #Esign_docusign_create {
      max-width: calc(100% - 40px);
      left: calc(50%);
      top: 55%;
      z-index: 999999999999 !important; } }

#enter-password-modal__create {
  max-width: 515px;
  overflow: auto; }
  @media (max-width: 768.98px) {
    #enter-password-modal__create {
      max-width: 300px; } }

.scroll-iteration-modal {
  overflow-y: unset; }

.application__step {
  padding: 0 20px 80px 20px; }
  .application__step.donate-form-page {
    padding: 0px 20px 0px 20px; }
  @media (max-width: 479.98px) {
    .application__step {
      padding: 0 10px 80px 10px; } }
  .application__step .H5HeadingDivider {
    margin: 60px 0px;
    border: dashed 1px #979797; }
  .application__step .application-section__jump {
    margin-top: 20px; }
    .application__step .application-section__jump .application-section__jump-inner {
      width: 80%;
      margin: auto;
      padding: 21px 29px;
      background-color: transparent;
      border-radius: 5px;
      max-height: 500px;
      overflow: auto;
      border: 1px solid #979797; }

#restriction-alert_message .sweet-alert div {
  text-align: left !important; }

.modal-utility .sweet-alert {
  padding: 32px 32px 16px 32px !important;
  width: 400px !important;
  border: 1px solid #1a282c !important; }
  @media (min-width: 769px) {
    .modal-utility .sweet-alert {
      width: auto; } }
  .modal-utility .sweet-alert .content {
    padding: 0 18px;
    text-align: left; }
    .modal-utility .sweet-alert .content .CTAgrey {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px; }
  .modal-utility .sweet-alert .action-footer {
    width: 100%;
    display: flex;
    justify-content: right; }
    .modal-utility .sweet-alert .action-footer button {
      padding: 8px 30px;
      font-size: 16px;
      height: fit-content; }
      .modal-utility .sweet-alert .action-footer button:focus-visible {
        outline: 2px solid #1E5558 !important;
        margin-right: 5px; }
    .modal-utility .sweet-alert .action-footer .cta-button {
      padding: 8px 20px; }
  .modal-utility .sweet-alert h2 {
    margin: 0; }
  .modal-utility .sweet-alert .title {
    line-height: 24px;
    font-family: 'Inter-Regular', sans-serif;
    color: #434343;
    font-size: 16px;
    font-weight: 600;
    text-align: left;
    margin-bottom: 10px; }

button.cta-button.CTAWhite.inverse.arrow.transparent-CTAButton {
  background: #fff;
  border: 0px; }

.application_resume {
  padding: 100px 20px 20px 20px;
  background-color: #FBFBF9;
  display: flex;
  justify-content: space-between; }
  @media only screen and (max-width: 1024px) {
    .application_resume {
      padding: 40px 20px 20px 20px; } }
  .application_resume .application-left-side-header-content {
    width: 25%; }
    .application_resume .application-left-side-header-content .left-side-content {
      background-color: #ffffff;
      width: 80%;
      border: 1px solid;
      border-radius: 5px;
      height: fit-content;
      padding: 15px;
      overflow: auto;
      max-height: 66vh; }
      .application_resume .application-left-side-header-content .left-side-content::-webkit-scrollbar {
        display: block;
        width: 5px;
        color: #979797; }
      .application_resume .application-left-side-header-content .left-side-content::-webkit-scrollbar-track {
        background: transparent; }
      .application_resume .application-left-side-header-content .left-side-content::-webkit-scrollbar-thumb {
        background-color: #979797;
        border-radius: 2px; }
      .application_resume .application-left-side-header-content .left-side-content .application-header {
        padding: unset !important; }
    @media (max-width: 479.98px) {
      .application_resume .application-left-side-header-content {
        display: none; } }
    @media only screen and (min-width: 0px) and (max-width: 770px) {
      .application_resume .application-left-side-header-content {
        display: none; } }
    @media only screen and (min-width: 769px) and (max-width: 1024px) {
      .application_resume .application-left-side-header-content {
        position: relative;
        inset: 60px 0px; } }
  .application_resume #application-form-content-data {
    position: absolute;
    overflow: auto;
    width: 48%;
    inset: 70px;
    right: 25%;
    left: 22.5%; }
    @media only screen and (min-width: 0px) and (max-width: 1023px) {
      .application_resume #application-form-content-data {
        left: 27%;
        width: 70%; } }
    .application_resume #application-form-content-data::-webkit-scrollbar {
      display: block;
      width: 5px;
      color: #979797; }
    .application_resume #application-form-content-data::-webkit-scrollbar-track {
      background: transparent; }
    .application_resume #application-form-content-data::-webkit-scrollbar-thumb {
      background-color: #979797;
      border-right: none;
      border-left: none; }
  .application_resume .jump-to-section-container {
    width: 25%; }
    @media only screen and (max-width: 1024px) {
      .application_resume .jump-to-section-container {
        inset: 60px 0px;
        position: relative; } }
    @media only screen and (min-width: 0px) and (max-width: 1023px) {
      .application_resume .jump-to-section-container {
        display: none; } }
    .application_resume .jump-to-section-container .application-section__jump {
      border: 1px solid;
      border-radius: 5px;
      padding: 10px 20px 20px 20px;
      overflow: auto;
      height: calc(100vh - 10rem);
      background: #ffffff; }
      .application_resume .jump-to-section-container .application-section__jump .application-section__jump-inner {
        padding-bottom: 50px; }
        .application_resume .jump-to-section-container .application-section__jump .application-section__jump-inner .application-radio-buttons__visualization {
          border: none; }
      .application_resume .jump-to-section-container .application-section__jump::-webkit-scrollbar {
        display: block;
        width: 5px; }
      .application_resume .jump-to-section-container .application-section__jump::-webkit-scrollbar-track {
        background: transparent; }
      .application_resume .jump-to-section-container .application-section__jump::-webkit-scrollbar-thumb {
        background-color: #979797;
        border-right: none;
        border-left: none;
        border-radius: 2px; }
  .application_resume .footer-button-container {
    width: 100%;
    background: white;
    display: flex;
    align-items: center;
    position: fixed;
    bottom: 0px;
    left: 0px;
    justify-content: space-between;
    margin: 0px;
    padding: 9px 0px;
    border-radius: 5px;
    border-top: 1px solid #c2b9b9;
    z-index: 8; }
    .application_resume .footer-button-container .left-gap {
      width: 22.5%; }
      @media (max-width: 768.98px) {
        .application_resume .footer-button-container .left-gap {
          width: unset; } }
      @media only screen and (min-width: 770px) and (max-width: 1023px) {
        .application_resume .footer-button-container .left-gap {
          width: 25%; } }
    .application_resume .footer-button-container .right-gap {
      width: 25%; }
      @media only screen and (min-width: 0px) and (max-width: 1023px) {
        .application_resume .footer-button-container .right-gap {
          width: unset; } }

.iteration-modal-save-changes-alert div div {
  z-index: 1000000 !important; }

.centerText {
  text-align: unset !important; }

#application-form-action-button-container,
.application-step-form-container {
  display: flex;
  width: 47%;
  align-items: center;
  gap: 30px; }
  @media (max-width: 768.98px) {
    #application-form-action-button-container,
    .application-step-form-container {
      width: unset;
      gap: 20px; } }
  @media (max-width: 479.98px) {
    #application-form-action-button-container,
    .application-step-form-container {
      gap: 5px !important;
      padding: 0px 5px; } }
  @media only screen and (min-width: 480px) and (max-width: 770px) {
    #application-form-action-button-container,
    .application-step-form-container {
      padding: 0px 10px; } }
  @media only screen and (min-width: 770px) and (max-width: 1024px) {
    #application-form-action-button-container,
    .application-step-form-container {
      width: 70%; } }
  #application-form-action-button-container__previous-step,
  .application-step-form-container__previous-step {
    width: 25%; }
    @media (max-width: 768.98px) {
      #application-form-action-button-container__previous-step,
      .application-step-form-container__previous-step {
        width: unset; } }
    #application-form-action-button-container__previous-step .cta-button,
    .application-step-form-container__previous-step .cta-button {
      background-color: #ffffff;
      color: #1E5558;
      border: 1px solid #1E5558;
      padding: 10px 32px 10px 18px; }
      @media (max-width: 479.98px) {
        #application-form-action-button-container__previous-step .cta-button,
        .application-step-form-container__previous-step .cta-button {
          padding: 10px 20px 10px 5px; } }
      #application-form-action-button-container__previous-step .cta-button__text,
      .application-step-form-container__previous-step .cta-button__text {
        font-size: 14px; }
      #application-form-action-button-container__previous-step .cta-button:hover,
      .application-step-form-container__previous-step .cta-button:hover {
        background-color: #e2e2e2; }
      #application-form-action-button-container__previous-step .cta-button .cta-button__arrow,
      .application-step-form-container__previous-step .cta-button .cta-button__arrow {
        margin-left: 10px; }
        #application-form-action-button-container__previous-step .cta-button .cta-button__arrow path,
        .application-step-form-container__previous-step .cta-button .cta-button__arrow path {
          margin-right: 7px;
          fill: #1E5558; }
  #application-form-action-button-container__step-current-status,
  .application-step-form-container__step-current-status {
    display: flex;
    align-items: center;
    justify-content: end;
    width: 25%; }
    @media (max-width: 768.98px) {
      #application-form-action-button-container__step-current-status,
      .application-step-form-container__step-current-status {
        width: unset; } }
    #application-form-action-button-container__step-current-status .CTABlack,
    .application-step-form-container__step-current-status .CTABlack {
      font-size: 14px; }
  #application-form-action-button-container__next-step,
  .application-step-form-container__next-step {
    width: 50%;
    display: flex;
    align-items: center;
    gap: 20px;
    justify-content: end; }
    @media (max-width: 768.98px) {
      #application-form-action-button-container__next-step,
      .application-step-form-container__next-step {
        width: unset;
        gap: 20px; } }
    @media (max-width: 479.98px) {
      #application-form-action-button-container__next-step,
      .application-step-form-container__next-step {
        gap: 5px !important; } }
    #application-form-action-button-container__next-step .save-application-button .cta-button,
    .application-step-form-container__next-step .save-application-button .cta-button {
      background-color: #ffffff;
      color: #1E5558;
      border: 1px solid #1E5558;
      padding: 8px 50px;
      height: 48px; }
      #application-form-action-button-container__next-step .save-application-button .cta-button:disabled,
      .application-step-form-container__next-step .save-application-button .cta-button:disabled {
        color: #FFFFFF; }
      #application-form-action-button-container__next-step .save-application-button .cta-button__text,
      .application-step-form-container__next-step .save-application-button .cta-button__text {
        font-size: 14px; }
      #application-form-action-button-container__next-step .save-application-button .cta-button:hover,
      .application-step-form-container__next-step .save-application-button .cta-button:hover {
        background-color: #e2e2e2; }
      @media (max-width: 479.98px) {
        #application-form-action-button-container__next-step .save-application-button .cta-button,
        .application-step-form-container__next-step .save-application-button .cta-button {
          padding: 8px 35px; } }
      #application-form-action-button-container__next-step .save-application-button .cta-button .cta-button__arrow,
      .application-step-form-container__next-step .save-application-button .cta-button .cta-button__arrow {
        margin-left: 10px; }
        #application-form-action-button-container__next-step .save-application-button .cta-button .cta-button__arrow path,
        .application-step-form-container__next-step .save-application-button .cta-button .cta-button__arrow path {
          fill: #1E5558; }
    #application-form-action-button-container__next-step .save-application-button .save-application,
    .application-step-form-container__next-step .save-application-button .save-application {
      margin-top: unset !important; }
    #application-form-action-button-container__next-step .next-page-and-review-submit-btn .next-page-button,
    .application-step-form-container__next-step .next-page-and-review-submit-btn .next-page-button {
      background-color: #1E5558;
      border: 1px solid #1E5558;
      padding: 10px 15px 10px 20px; }
      @media only screen and (min-width: 480px) {
        #application-form-action-button-container__next-step .next-page-and-review-submit-btn .next-page-button,
        .application-step-form-container__next-step .next-page-and-review-submit-btn .next-page-button {
          padding: 10px 25px 10px 35px; } }
