.user-account-gradebook-page {
  max-width: 100%;
  margin: 0 auto;
  padding: 0px 45px;
  min-height: 100vh; }
  @media (max-width: 768.98px) {
    .user-account-gradebook-page {
      padding: 0px 20px; } }
  .user-account-gradebook-page .logo-and-account-title-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 25px; }
    @media (max-width: 768.98px) {
      .user-account-gradebook-page .logo-and-account-title-container {
        margin-bottom: 0px; } }
    .user-account-gradebook-page .logo-and-account-title-container .logo img {
      height: 100px;
      width: 100px;
      object-fit: contain; }
    .user-account-gradebook-page .logo-and-account-title-container .account-title {
      margin-left: 15px;
      font-size: 24px; }
      @media (max-width: 768.98px) {
        .user-account-gradebook-page .logo-and-account-title-container .account-title {
          line-height: 1;
          font-size: 20px; } }
  .user-account-gradebook-page .user-account-page-user-details-section {
    margin-bottom: 32px;
    padding-top: 112px; }
    @media (max-width: 768.98px) {
      .user-account-gradebook-page .user-account-page-user-details-section {
        margin-bottom: 20px;
        padding-top: 20px; } }
    .user-account-gradebook-page .user-account-page-user-details-section .title-with-icon {
      display: flex;
      align-items: center;
      justify-content: space-between; }
      .user-account-gradebook-page .user-account-page-user-details-section .title-with-icon .H1DesktopGreen {
        color: #1a282c;
        font-weight: 600;
        font-size: 26px !important; }
      @media (max-width: 768.98px) {
        .user-account-gradebook-page .user-account-page-user-details-section .title-with-icon svg {
          width: 150px; } }
      @media (max-width: 768.98px) {
        .user-account-gradebook-page .user-account-page-user-details-section .title-with-icon .H2DesktopGreen {
          margin-bottom: 20px; } }
    .user-account-gradebook-page .user-account-page-user-details-section .user-account-default-profile-image {
      width: 80px;
      height: 80px;
      background: #f6f6f6;
      border-radius: 100%;
      margin-right: 22px;
      display: flex;
      align-items: center;
      justify-content: center; }
      .user-account-gradebook-page .user-account-page-user-details-section .user-account-default-profile-image #user-account-page-user-icon {
        width: 41px; }
  .user-account-gradebook-page .H5HeadingDivider {
    margin: 16px 0 41px 0px;
    border: dashed 1px #e6e9d7; }
  .user-account-gradebook-page .applicant-new-gradebooks__add {
    display: flex;
    align-items: center; }
    .user-account-gradebook-page .applicant-new-gradebooks__add svg {
      margin-right: 15px; }
  .user-account-gradebook-page .gradebook-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
    margin-left: 95px; }
    .user-account-gradebook-page .gradebook-section .applicant-new-gradebook-add {
      display: flex;
      align-items: center; }
      .user-account-gradebook-page .gradebook-section .applicant-new-gradebook-add .donor-new-scholarship-page__plus {
        margin-right: 15px; }
    @media (max-width: 768.98px) {
      .user-account-gradebook-page .gradebook-section {
        margin-left: unset; } }
    @media (max-width: 768.98px) {
      .user-account-gradebook-page .gradebook-section .left-title-section .event-head {
        margin-bottom: 20px; } }
    .user-account-gradebook-page .gradebook-section .applicant-new-gradebook .applicant-new-gradebook-add {
      display: flex;
      align-items: center; }
      .user-account-gradebook-page .gradebook-section .applicant-new-gradebook .applicant-new-gradebook-add svg {
        margin-right: 10px; }
      .user-account-gradebook-page .gradebook-section .applicant-new-gradebook .applicant-new-gradebook-add .donor-new-scholarship-page__plus {
        height: 48px;
        width: 48px;
        border-radius: 50%;
        border: 2px solid white;
        margin-left: -10px; }

.skeleton-loader__container > table {
  background: transparent; }

#gradebook-list {
  min-height: 50vh;
  margin-left: 95px;
  margin-bottom: 50px; }
  @media (max-width: 768.98px) {
    #gradebook-list {
      margin-left: unset;
      margin-bottom: 20px; } }
  #gradebook-list table tbody tr td:first-child {
    font-size: 18px;
    font-weight: 500; }
