.connect-container {
  max-width: 1110px;
  margin-top: 40px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 16px; }
  .connect-container h1 {
    font-size: 26px;
    margin-top: 20px;
    font-family: "Inter-Light"; }
  .connect-container h6 {
    font-family: "Inter-Light";
    padding: 12px 0px;
    line-height: 1.3;
    width: 80%;
    margin: auto; }
  .connect-container .connect-header-section {
    display: flex;
    flex-direction: column;
    text-align: center;
    background-color: #1e5558;
    color: white;
    margin-bottom: 35px;
    border-radius: 8px;
    margin-left: 16px;
    margin-right: 16px; }
  .connect-container .connect-container_inner {
    margin: 0 40px;
    border-top: 1px solid #737373; }
    .connect-container .connect-container_inner #main-section-image-text {
      gap: 80px;
      padding: 20px 0;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      margin-top: 20px;
      align-items: center; }
      @media (max-width: 768.98px) {
        .connect-container .connect-container_inner #main-section-image-text {
          grid-template-columns: repeat(1, 1fr); } }
      @media (max-width: 768.98px) {
        .connect-container .connect-container_inner #main-section-image-text #cell-1 {
          order: 1; }
        .connect-container .connect-container_inner #main-section-image-text #cell-2 {
          order: 2; }
        .connect-container .connect-container_inner #main-section-image-text #cell-3 {
          order: 4; }
        .connect-container .connect-container_inner #main-section-image-text #cell-4 {
          order: 3; }
        .connect-container .connect-container_inner #main-section-image-text #cell-5 {
          order: 5; }
        .connect-container .connect-container_inner #main-section-image-text #cell-6 {
          order: 6; } }
      .connect-container .connect-container_inner #main-section-image-text .section-text {
        margin-top: 22px;
        font-family: "Inter-Light"; }
        .connect-container .connect-container_inner #main-section-image-text .section-text .section-heading {
          font-family: 'Inter-Medium';
          font-size: 24px;
          font-weight: 500;
          line-height: 24px;
          letter-spacing: 0em;
          color: #1E5558; }
        .connect-container .connect-container_inner #main-section-image-text .section-text p {
          margin-top: 8px;
          font-size: large;
          font-family: 'Inter-Regular'; }
      .connect-container .connect-container_inner #main-section-image-text .image-section img {
        max-height: 300px;
        min-height: 300px;
        width: 100%;
        border: 1px solid #1a282c; }
  .connect-container .started-section {
    display: flex;
    flex-direction: column;
    text-align: center;
    background-color: #592840;
    color: #ffffff;
    width: 80%;
    margin: auto;
    border-radius: 8px;
    font-family: "Inter-Light";
    margin-bottom: 22px;
    padding-bottom: 10px; }
    .connect-container .started-section h1 {
      font-size: 26px;
      margin-top: 20px; }
    .connect-container .started-section h6 {
      padding: 8px; }
      .connect-container .started-section h6 a {
        text-decoration: underline;
        font-size: large; }
