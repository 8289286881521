.polar-question {
  display: flex;
  flex-direction: column;
  margin: 32px 0 0; }

.polar-question__label {
  display: inline-flex;
  align-items: center;
  align-self: flex-start;
  cursor: pointer; }

.polar-question__label--second {
  margin: 24px 0 0; }

.polar-question__input {
  display: none; }

.polar-question__visualization {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 15px 0 0;
  width: 30px;
  height: 30px;
  border: 1px solid #e6e9d7;
  border-radius: 50%; }

.polar-question--scorecard .polar-question__visualization {
  border-color: rgba(26, 40, 44, 0.1); }

.polar-question__input:checked + .polar-question__visualization {
  background-color: #1C21DF;
  border-color: #1C21DF; }

.polar-question__input:checked + .polar-question__visualization > .polar-question__icon {
  display: block; }

.polar-question__text {
  line-height: 1.5;
  color: #979797;
  font-family: 'Inter-Medium';
  font-size: 18px;
  font-weight: 500;
  letter-spacing: normal; }

.polar-question__input:checked ~ .polar-question__text {
  color: #1a282c; }

.polar-question__textarea {
  margin: 24px 0 0 !important; }

.polar-question--scorecard .polar-question__textarea {
  margin: 21px 0 0 !important; }

.polar-question--scorecard .polar-question__textarea textarea {
  margin: 0;
  padding: 14px 20px;
  height: 140px;
  line-height: 24px;
  font-size: 16px;
  background-color: #ffffff;
  border: none; }

.polar-question--scorecard .polar-question__textarea textarea::placeholder {
  color: rgba(26, 40, 44, 0.6); }

.polar-question__icon {
  position: relative;
  left: 1px;
  display: none; }
